import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Working2 from "../animation/Working2";
import StepContent from "@material-ui/core/StepContent";
import StepLabel from "@material-ui/core/StepLabel";
import Step from "@material-ui/core/Step";
import Stepper from "@material-ui/core/Stepper";
import Grid from "@material-ui/core/Grid";

const styles = {};
const Component = () => {
  return (
    <>
      <center>
        <div style={{ width: "100%", paddingTop: "16px", maxWidth: "400px" }}>
          <Working2 />
        </div>
      </center>
      <Typography
        color="primary"
        style={{
          fontWeight: 300,
          paddingTop: "0px",
          paddingBottom: "24px",
          textAlign: "center",
        }}
        variant="h3"
      >
        our process
      </Typography>
      <p />
      <div style={{ marginTop: "24px", marginBottom: "24px" }}>
        <Grid alignItems="stretch" container justify="center" spacing={0}>
          <Grid item md={8} style={{ textAlign: "left" }} xs={12}>
            <Stepper
              className="overflowVisible"
              orientation="vertical"
              style={{
                backgroundColor: "inherit",
                paddingTop: 0,
              }}
            >
              <Step active={true} key={1}>
                <StepLabel>
                  <Typography component="span" variant="h6">
                    We rapidly discover your core needs
                  </Typography>
                </StepLabel>
                <StepContent>
                  <Typography component="span" variant="body1">
                    We're not big fans of extending "discovery" sessions. As
                    such, we have a series of exercises to very quickly get
                    sufficient data to align all stakeholders and get moving.
                  </Typography>
                </StepContent>
              </Step>
              <Step active={true} key={1}>
                <StepLabel>
                  <Typography component="span" variant="h6">
                    Security and DevOps are top-of-mind
                  </Typography>
                </StepLabel>
                <StepContent>
                  <Typography component="span" variant="body1">
                    Before we begin, we discuss and plan the project in terms of
                    its security contours. What vectors are there for leaks of
                    PII? What risks are there in terms of harming existing
                    systems? What's the process, now, for system failures? How
                    can we build a new component that alarms itself properly
                    within your existing DevOps procedures?
                  </Typography>
                </StepContent>
              </Step>
              <Step active={true} key={1}>
                <StepLabel>
                  <Typography component="span" variant="h6">
                    We generate real value for you with a{" "}
                    <b>Proof-of-Concept</b> (POC)
                  </Typography>
                </StepLabel>
                <StepContent>
                  <Typography component="span" variant="body1">
                    As much as possible, we favor off-the-shelf open source
                    components. This means that there's less custom "innovation"
                    (that can be brittle or require special care and feeding).
                  </Typography>
                </StepContent>
              </Step>
              <Step active={true} key={1}>
                <StepLabel>
                  <Typography component="span" variant="h6">
                    We fold our learnings from the POC into{" "}
                    <b>Phase 1 Integration</b>
                  </Typography>
                </StepLabel>
                <StepContent>
                  <Typography component="span" variant="body1">
                    It's here that we can discuss richer budgets and timelines.
                    But this proposal will be "battle-tested" from our POC. This
                    hands-on experience working together will provide real world
                    discovery.
                    <p />
                    Our Phase 1 approach is to drive forward integration with
                    existing processes, products, and people. This can be hard
                    work! From the engineering side, it can mean APIs, webhooks,
                    Sharepoint, and other technical nuts-and-bolts. But, often,
                    it means hard work to ensure that the project properly lands
                    within your organization. We want to see the software
                    working, end-to-end.
                  </Typography>
                </StepContent>
              </Step>
              <Step active={true} key={1}>
                <StepLabel>
                  <Typography component="span" variant="h6">
                    We rapidly iterate with <b>Phase 2 Scale</b>
                  </Typography>
                </StepLabel>
                <StepContent>
                  <Typography component="span" variant="body1">
                    It's at this time that we can expand our work to take on
                    more data, both "horizontally" (in the form of more data
                    sources) and "vertically" (in the form of more variations).
                    It's also here that we roll out our software to a larger set
                    of customers and other end-users.
                  </Typography>
                </StepContent>
              </Step>
              <Step active={true} key={1}>
                <StepLabel>
                  <Typography component="span" variant="h6">
                    We carefully segue into <b>Phase 3 Innovation</b>
                  </Typography>
                </StepLabel>
                <StepContent>
                  <Typography component="span" variant="body1">
                    We actually think the word "innovation" can be a "bad word."
                    In software, it's rarely a good idea to "innovate." Why?
                    Because "innovation" often means re-doing work that already
                    exists as an off-the-shelf or open-source component. It can
                    mean creating something that's brittle, quickly outdated, or
                    a hacky solution that ultimately fails.
                    <p />
                    When we think of innovation, we think about the real value
                    that can come from a project after deep understanding and
                    experience with it. This is why we leave this aspect to the
                    very end. It's here that we can find ways to generate new
                    metrics, or new machine learning, to surface insights that
                    were never expected or anticipated when the project was
                    formed. For example, while earlier phases might arm staff
                    with automation to simplify their work, this phase might
                    generate patterns and learnings from the pool of data we've
                    generated. These can bubble up in an organization in
                    dashboards, forecasts, and other executive metrics that are
                    perfect for the C Suite.
                  </Typography>
                </StepContent>
              </Step>
              <Step active={true} key={1}>
                <StepLabel>
                  <Typography component="span" variant="h6">
                    We reflect, learn, and plan
                  </Typography>
                </StepLabel>
                <StepContent>
                  <Typography component="span" variant="body1">
                    We leave room at the end of every project (and, often, at
                    the end of key phases) to retrospect. This helps us improve
                    future engagements. And plan for next steps.
                  </Typography>
                </StepContent>
              </Step>
            </Stepper>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default withStyles(styles, { withTheme: true })(Component);
