import React from "react";
import { withStyles } from "@material-ui/core/styles";
import List from "../List";
import Typography from "@material-ui/core/Typography";
import Tasks from "../animation/Tasks";

const styles = {};
const Component = ({ services }) => {
  return (
    <>
      <center>
        <div style={{ width: "100%", paddingTop: "32px", maxWidth: "400px" }}>
          <Tasks />
        </div>
      </center>
      <Typography
        color="primary"
        style={{
          fontWeight: 300,
          paddingTop: "0px",
          paddingBottom: "24px",
          textAlign: "center",
        }}
        variant="h3"
      >
        our skills
      </Typography>
      <p />
      <div style={{ marginTop: "24px", marginBottom: "24px" }}>
        <List items={services} />
      </div>
    </>
  );
};

export default withStyles(styles, { withTheme: true })(Component);
