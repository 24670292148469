import React from "react";
import Lottie from "react-lottie-wrapper";

const Component = () => {
  return (
    <>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: {
            v: "5.2.1",
            fr: 30,
            ip: 0,
            op: 180,
            w: 1920,
            h: 1080,
            nm: "Person editing shapes Female black",
            ddd: 0,
            assets: [
              {
                id: "comp_0",
                layers: [
                  {
                    ddd: 0,
                    ind: 1,
                    ty: 4,
                    nm: "Path 5",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [57.698, 32.429, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                              a: 0,
                              k: {
                                i: [
                                  [0, 0],
                                  [2.28, 8.02],
                                ],
                                o: [
                                  [-1.51, -7.34],
                                  [0, 0],
                                ],
                                v: [
                                  [5.152, 22.388],
                                  [-5.148, -22.392],
                                ],
                                c: false,
                              },
                              ix: 2,
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                          },
                          {
                            ty: "st",
                            c: {
                              a: 0,
                              k: [1, 0.419607996941, 0.580392003059, 1],
                              ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 2.672, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            ml2: { a: 0, k: 4, ix: 8 },
                            nm: "Stroke 1",
                            mn: "ADBE Vector Graphic - Stroke",
                            hd: false,
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                          },
                        ],
                        nm: "Path",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                      },
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0,
                  },
                  {
                    ddd: 0,
                    ind: 2,
                    ty: 4,
                    nm: "Path 4",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [40.098, 28.686, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                              a: 0,
                              k: {
                                i: [
                                  [0, 0],
                                  [3.27, 9.45],
                                ],
                                o: [
                                  [-3.43, -14.76],
                                  [0, 0],
                                ],
                                v: [
                                  [7.318, 26.547],
                                  [-7.322, -26.543],
                                ],
                                c: false,
                              },
                              ix: 2,
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                          },
                          {
                            ty: "st",
                            c: {
                              a: 0,
                              k: [1, 0.419607996941, 0.580392003059, 1],
                              ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 2.672, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            ml2: { a: 0, k: 4, ix: 8 },
                            nm: "Stroke 1",
                            mn: "ADBE Vector Graphic - Stroke",
                            hd: false,
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                          },
                        ],
                        nm: "Path",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                      },
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0,
                  },
                  {
                    ddd: 0,
                    ind: 3,
                    ty: 4,
                    nm: "Path 3",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [72.502, 37.689, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                              a: 0,
                              k: {
                                i: [
                                  [0, 0],
                                  [3.13, 11.45],
                                ],
                                o: [
                                  [-2.6, -14.31],
                                  [0, 0],
                                ],
                                v: [
                                  [4.184, 19.187],
                                  [-4.186, -19.183],
                                ],
                                c: false,
                              },
                              ix: 2,
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                          },
                          {
                            ty: "st",
                            c: {
                              a: 0,
                              k: [1, 0.419607996941, 0.580392003059, 1],
                              ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 2.672, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            ml2: { a: 0, k: 4, ix: 8 },
                            nm: "Stroke 1",
                            mn: "ADBE Vector Graphic - Stroke",
                            hd: false,
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                          },
                        ],
                        nm: "Path",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                      },
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0,
                  },
                  {
                    ddd: 0,
                    ind: 4,
                    ty: 4,
                    nm: "Path 2",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [50.542, 78.12, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                              a: 0,
                              k: {
                                i: [
                                  [-19.35, -18.87],
                                  [5.6, -25.68],
                                  [10.89, 9.64],
                                  [2.27, 8.8],
                                  [0, 0],
                                  [0.3, 11.23],
                                ],
                                o: [
                                  [9.35, 9.12],
                                  [-6.81, -1.62],
                                  [-13.6, -12.05],
                                  [-2.49, -9.65],
                                  [0, 0],
                                  [-0.14, -5.15],
                                ],
                                v: [
                                  [29.367, -45.082],
                                  [45.457, 68.548],
                                  [-11.913, 68.038],
                                  [-46.733, -12.532],
                                  [-13.873, -6.342],
                                  [-34.823, -70.912],
                                ],
                                c: true,
                              },
                              ix: 2,
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.996078014374,
                                0.800000011921,
                                0.823529005051,
                                1,
                              ],
                              ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false,
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                          },
                        ],
                        nm: "Path",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                      },
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0,
                  },
                ],
              },
              {
                id: "comp_1",
                layers: [
                  {
                    ddd: 0,
                    ind: 1,
                    ty: 4,
                    nm: "Path 5",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [19.319, 61.519, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                              a: 0,
                              k: {
                                i: [
                                  [0, 0],
                                  [2.17, 11.67],
                                ],
                                o: [
                                  [-3.89, -14.01],
                                  [0, 0],
                                ],
                                v: [
                                  [4.591, 19.091],
                                  [-4.589, -19.089],
                                ],
                                c: false,
                              },
                              ix: 2,
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                          },
                          {
                            ty: "st",
                            c: {
                              a: 0,
                              k: [1, 0.419607996941, 0.580392003059, 1],
                              ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 2.672, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            ml2: { a: 0, k: 4, ix: 8 },
                            nm: "Stroke 1",
                            mn: "ADBE Vector Graphic - Stroke",
                            hd: false,
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                          },
                        ],
                        nm: "Path",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                      },
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0,
                  },
                  {
                    ddd: 0,
                    ind: 2,
                    ty: 4,
                    nm: "Path 4",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [30.354, 50.334, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                              a: 0,
                              k: {
                                i: [
                                  [0, 0],
                                  [1.44, 8.21],
                                ],
                                o: [
                                  [-1.84, -7.27],
                                  [0, 0],
                                ],
                                v: [
                                  [5.119, 22.401],
                                  [-5.121, -22.399],
                                ],
                                c: false,
                              },
                              ix: 2,
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                          },
                          {
                            ty: "st",
                            c: {
                              a: 0,
                              k: [1, 0.419607996941, 0.580392003059, 1],
                              ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 2.672, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            ml2: { a: 0, k: 4, ix: 8 },
                            nm: "Stroke 1",
                            mn: "ADBE Vector Graphic - Stroke",
                            hd: false,
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                          },
                        ],
                        nm: "Path",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                      },
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0,
                  },
                  {
                    ddd: 0,
                    ind: 3,
                    ty: 4,
                    nm: "Path 3",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [44.563, 39.297, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                              a: 0,
                              k: {
                                i: [
                                  [0, 0],
                                  [1.17, 9.93],
                                ],
                                o: [
                                  [-3.34, -14.77],
                                  [0, 0],
                                ],
                                v: [
                                  [4.977, 27.078],
                                  [-4.973, -27.082],
                                ],
                                c: false,
                              },
                              ix: 2,
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                          },
                          {
                            ty: "st",
                            c: {
                              a: 0,
                              k: [1, 0.419607996941, 0.580392003059, 1],
                              ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 2.672, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            ml2: { a: 0, k: 4, ix: 8 },
                            nm: "Stroke 1",
                            mn: "ADBE Vector Graphic - Stroke",
                            hd: false,
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                          },
                        ],
                        nm: "Path",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                      },
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0,
                  },
                  {
                    ddd: 0,
                    ind: 4,
                    ty: 4,
                    nm: "Path 2",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [54.537, 89.357, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                              a: 0,
                              k: {
                                i: [
                                  [9.19, -25.4],
                                  [-16.23, -20.68],
                                  [-5.6, 13.42],
                                  [1.97, 9.77],
                                  [0, 0],
                                  [4.62, 10.24],
                                ],
                                o: [
                                  [-4.45, 12.29],
                                  [5.43, -4.42],
                                  [6.99, -16.77],
                                  [-1.96, -9.77],
                                  [0, 0],
                                  [-2.12, -4.69],
                                ],
                                v: [
                                  [-43.9, -28.806],
                                  [-8.89, 80.494],
                                  [42.53, 55.034],
                                  [33.47, -49.436],
                                  [11.89, -12.766],
                                  [2.62, -80.016],
                                ],
                                c: true,
                              },
                              ix: 2,
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.996078014374,
                                0.800000011921,
                                0.823529005051,
                                1,
                              ],
                              ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false,
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                          },
                        ],
                        nm: "Path",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                      },
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0,
                  },
                ],
              },
              {
                id: "comp_2",
                layers: [
                  {
                    ddd: 0,
                    ind: 1,
                    ty: 4,
                    nm: "Rectangle 11",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: {
                        a: 1,
                        k: [
                          {
                            i: { x: 0.833, y: 0.833 },
                            o: { x: 0.167, y: 0.167 },
                            n: "0p833_0p833_0p167_0p167",
                            t: 20,
                            s: [670.572, 30.556, 0],
                            e: [308.572, 30.556, 0],
                            to: [-60.3333320617676, 0, 0],
                            ti: [60.3333320617676, 0, 0],
                          },
                          { t: 170 },
                        ],
                        ix: 2,
                      },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                            o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                            n: [
                              "0p667_1_0p333_0",
                              "0p667_1_0p333_0",
                              "0p667_1_0p333_0",
                            ],
                            t: 160,
                            s: [100, 100, 100],
                            e: [100, 0, 100],
                          },
                          { t: 170 },
                        ],
                        ix: 6,
                      },
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ty: "rc",
                            d: 1,
                            s: { a: 0, k: [56.797, 56.797], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 0, ix: 4 },
                            nm: "Rectangle Path 1",
                            mn: "ADBE Vector Shape - Rect",
                            hd: false,
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [1, 0.61960798502, 0.725489974022, 1],
                              ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false,
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                          },
                        ],
                        nm: "Rectangle",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                      },
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0,
                  },
                  {
                    ddd: 0,
                    ind: 2,
                    ty: 4,
                    nm: "Rectangle 10",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: {
                        a: 1,
                        k: [
                          {
                            i: { x: 0.833, y: 0.833 },
                            o: { x: 0.167, y: 0.167 },
                            n: "0p833_0p833_0p167_0p167",
                            t: 20,
                            s: [577.778, 30.556, 0],
                            e: [215.778, 30.556, 0],
                            to: [-60.3333320617676, 0, 0],
                            ti: [60.3333320617676, 0, 0],
                          },
                          { t: 170 },
                        ],
                        ix: 2,
                      },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                            o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                            n: [
                              "0p667_1_0p333_0",
                              "0p667_1_0p333_0",
                              "0p667_1_0p333_0",
                            ],
                            t: 157,
                            s: [100, 100, 100],
                            e: [100, 0, 100],
                          },
                          { t: 167 },
                        ],
                        ix: 6,
                      },
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ty: "rc",
                            d: 1,
                            s: { a: 0, k: [56.797, 56.797], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 0, ix: 4 },
                            nm: "Rectangle Path 1",
                            mn: "ADBE Vector Shape - Rect",
                            hd: false,
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [1, 0.61960798502, 0.725489974022, 1],
                              ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false,
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                          },
                        ],
                        nm: "Rectangle",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                      },
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0,
                  },
                  {
                    ddd: 0,
                    ind: 3,
                    ty: 4,
                    nm: "Rectangle 9",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: {
                        a: 1,
                        k: [
                          {
                            i: { x: 0.833, y: 0.833 },
                            o: { x: 0.167, y: 0.167 },
                            n: "0p833_0p833_0p167_0p167",
                            t: 20,
                            s: [484.977, 30.556, 0],
                            e: [122.977, 30.556, 0],
                            to: [-60.3333320617676, 0, 0],
                            ti: [60.3333320617676, 0, 0],
                          },
                          { t: 170 },
                        ],
                        ix: 2,
                      },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                            o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                            n: [
                              "0p667_1_0p333_0",
                              "0p667_1_0p333_0",
                              "0p667_1_0p333_0",
                            ],
                            t: 154,
                            s: [100, 100, 100],
                            e: [100, 0, 100],
                          },
                          { t: 164 },
                        ],
                        ix: 6,
                      },
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ty: "rc",
                            d: 1,
                            s: { a: 0, k: [56.797, 56.797], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 0, ix: 4 },
                            nm: "Rectangle Path 1",
                            mn: "ADBE Vector Shape - Rect",
                            hd: false,
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [1, 0.61960798502, 0.725489974022, 1],
                              ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false,
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                          },
                        ],
                        nm: "Rectangle",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                      },
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0,
                  },
                  {
                    ddd: 0,
                    ind: 4,
                    ty: 4,
                    nm: "Rectangle 8",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: {
                        a: 1,
                        k: [
                          {
                            i: { x: 0.833, y: 0.833 },
                            o: { x: 0.167, y: 0.167 },
                            n: "0p833_0p833_0p167_0p167",
                            t: 20,
                            s: [392.182, 30.556, 0],
                            e: [30.182, 30.556, 0],
                            to: [-60.3333320617676, 0, 0],
                            ti: [60.3333320617676, 0, 0],
                          },
                          { t: 170 },
                        ],
                        ix: 2,
                      },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                            o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                            n: [
                              "0p667_1_0p333_0",
                              "0p667_1_0p333_0",
                              "0p667_1_0p333_0",
                            ],
                            t: 151,
                            s: [100, 100, 100],
                            e: [100, 0, 100],
                          },
                          { t: 161 },
                        ],
                        ix: 6,
                      },
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ty: "rc",
                            d: 1,
                            s: { a: 0, k: [56.797, 56.797], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 0, ix: 4 },
                            nm: "Rectangle Path 1",
                            mn: "ADBE Vector Shape - Rect",
                            hd: false,
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [1, 0.61960798502, 0.725489974022, 1],
                              ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false,
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                          },
                        ],
                        nm: "Rectangle",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                      },
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0,
                  },
                  {
                    ddd: 0,
                    ind: 5,
                    ty: 4,
                    nm: "Rectangle 7",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: {
                        a: 1,
                        k: [
                          {
                            i: { x: 0.833, y: 0.833 },
                            o: { x: 0.167, y: 0.167 },
                            n: "0p833_0p833_0p167_0p167",
                            t: 20,
                            s: [30.182, 30.556, 0],
                            e: [-335.818, 30.556, 0],
                            to: [-61, 0, 0],
                            ti: [61, 0, 0],
                          },
                          { t: 170 },
                        ],
                        ix: 2,
                      },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                            o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                            n: [
                              "0p667_1_0p333_0",
                              "0p667_1_0p333_0",
                              "0p667_1_0p333_0",
                            ],
                            t: 0,
                            s: [100, 0, 100],
                            e: [100, 100, 100],
                          },
                          { t: 10 },
                        ],
                        ix: 6,
                      },
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ty: "rc",
                            d: 1,
                            s: { a: 0, k: [56.797, 56.797], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 0, ix: 4 },
                            nm: "Rectangle Path 1",
                            mn: "ADBE Vector Shape - Rect",
                            hd: false,
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [1, 0.61960798502, 0.725489974022, 1],
                              ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false,
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                          },
                        ],
                        nm: "Rectangle",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                      },
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0,
                  },
                  {
                    ddd: 0,
                    ind: 6,
                    ty: 4,
                    nm: "Rectangle 6",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: {
                        a: 1,
                        k: [
                          {
                            i: { x: 0.833, y: 0.833 },
                            o: { x: 0.167, y: 0.167 },
                            n: "0p833_0p833_0p167_0p167",
                            t: 20,
                            s: [122.977, 30.556, 0],
                            e: [-243.023, 30.556, 0],
                            to: [-61, 0, 0],
                            ti: [61, 0, 0],
                          },
                          { t: 170 },
                        ],
                        ix: 2,
                      },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                            o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                            n: [
                              "0p667_1_0p333_0",
                              "0p667_1_0p333_0",
                              "0p667_1_0p333_0",
                            ],
                            t: 3.334,
                            s: [100, 0, 100],
                            e: [100, 100, 100],
                          },
                          { t: 13.333984375 },
                        ],
                        ix: 6,
                      },
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ty: "rc",
                            d: 1,
                            s: { a: 0, k: [56.797, 56.797], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 0, ix: 4 },
                            nm: "Rectangle Path 1",
                            mn: "ADBE Vector Shape - Rect",
                            hd: false,
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [1, 0.61960798502, 0.725489974022, 1],
                              ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false,
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                          },
                        ],
                        nm: "Rectangle",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                      },
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0,
                  },
                  {
                    ddd: 0,
                    ind: 7,
                    ty: 4,
                    nm: "Rectangle 5",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: {
                        a: 1,
                        k: [
                          {
                            i: { x: 0.833, y: 0.833 },
                            o: { x: 0.167, y: 0.167 },
                            n: "0p833_0p833_0p167_0p167",
                            t: 20,
                            s: [215.778, 30.556, 0],
                            e: [-150.222, 30.556, 0],
                            to: [-61, 0, 0],
                            ti: [61, 0, 0],
                          },
                          { t: 170 },
                        ],
                        ix: 2,
                      },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                            o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                            n: [
                              "0p667_1_0p333_0",
                              "0p667_1_0p333_0",
                              "0p667_1_0p333_0",
                            ],
                            t: 6.666,
                            s: [100, 0, 100],
                            e: [100, 100, 100],
                          },
                          { t: 16.666015625 },
                        ],
                        ix: 6,
                      },
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ty: "rc",
                            d: 1,
                            s: { a: 0, k: [56.797, 56.797], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 0, ix: 4 },
                            nm: "Rectangle Path 1",
                            mn: "ADBE Vector Shape - Rect",
                            hd: false,
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [1, 0.61960798502, 0.725489974022, 1],
                              ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false,
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                          },
                        ],
                        nm: "Rectangle",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                      },
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0,
                  },
                  {
                    ddd: 0,
                    ind: 8,
                    ty: 4,
                    nm: "Rectangle 4",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: {
                        a: 1,
                        k: [
                          {
                            i: { x: 0.833, y: 0.833 },
                            o: { x: 0.167, y: 0.167 },
                            n: "0p833_0p833_0p167_0p167",
                            t: 20,
                            s: [308.572, 30.556, 0],
                            e: [-57.428, 30.556, 0],
                            to: [-61, 0, 0],
                            ti: [61, 0, 0],
                          },
                          { t: 170 },
                        ],
                        ix: 2,
                      },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                            o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                            n: [
                              "0p667_1_0p333_0",
                              "0p667_1_0p333_0",
                              "0p667_1_0p333_0",
                            ],
                            t: 10,
                            s: [100, 0, 100],
                            e: [100, 100, 100],
                          },
                          { t: 20 },
                        ],
                        ix: 6,
                      },
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ty: "rc",
                            d: 1,
                            s: { a: 0, k: [56.797, 56.797], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 0, ix: 4 },
                            nm: "Rectangle Path 1",
                            mn: "ADBE Vector Shape - Rect",
                            hd: false,
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [1, 0.61960798502, 0.725489974022, 1],
                              ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false,
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                          },
                        ],
                        nm: "Rectangle",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                      },
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0,
                  },
                ],
              },
            ],
            layers: [
              {
                ddd: 0,
                ind: 1,
                ty: 4,
                nm: "Shape",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [996.186, 535.901, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0.81, -0.15],
                              [0.16, 0.8],
                            ],
                            o: [
                              [0.16, 0.81],
                              [-0.8, 0.16],
                              [0, 0],
                            ],
                            v: [
                              [18.842, 12.018],
                              [17.662, 13.758],
                              [15.922, 12.588],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ind: 1,
                        ty: "sh",
                        ix: 2,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [-0.1, 0.82],
                              [-0.81, -0.1],
                            ],
                            o: [
                              [-0.81, -0.1],
                              [0.1, -0.81],
                              [0, 0],
                            ],
                            v: [
                              [-17.56, -10.819],
                              [-18.86, -12.479],
                              [-17.21, -13.779],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 2",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ind: 2,
                        ty: "sh",
                        ix: 3,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [23.96, 2.85],
                              [0, 0],
                              [-1.48, -7.66],
                            ],
                            o: [
                              [-1.22, -6.31],
                              [0, 0],
                              [25.17, 3],
                              [0, 0],
                            ],
                            v: [
                              [15.922, 12.581],
                              [-17.558, -10.819],
                              [-17.208, -13.779],
                              [18.842, 12.021],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 3",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "mm",
                        mm: 1,
                        nm: "Merge Paths 1",
                        mn: "ADBE Vector Filter - Merge",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [0.7333329916, 0.678430974483, 0.745097994804, 1],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Shape",
                    np: 5,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 2,
                ty: 4,
                nm: "Shape",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [886.617, 549.447, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [-0.81, -0.14],
                              [-0.14, 0.81],
                            ],
                            o: [
                              [-0.14, 0.81],
                              [0.81, 0.14],
                              [0, 0],
                            ],
                            v: [
                              [-18.617, 12.369],
                              [-17.407, 14.089],
                              [-15.687, 12.879],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ind: 1,
                        ty: "sh",
                        ix: 2,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0.11, 0.82],
                              [0.81, -0.11],
                            ],
                            o: [
                              [0.81, -0.11],
                              [-0.12, -0.81],
                              [0, 0],
                            ],
                            v: [
                              [17.356, -11.152],
                              [18.626, -12.832],
                              [16.946, -14.102],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 2",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ind: 2,
                        ty: "sh",
                        ix: 3,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [-23.91, 3.3],
                              [0, 0],
                              [1.34, -7.68],
                            ],
                            o: [
                              [1.1, -6.33],
                              [0, 0],
                              [-25.11, 3.47],
                              [0, 0],
                            ],
                            v: [
                              [-15.685, 12.882],
                              [17.355, -11.148],
                              [16.945, -14.098],
                              [-18.615, 12.372],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 3",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "mm",
                        mm: 1,
                        nm: "Merge Paths 1",
                        mn: "ADBE Vector Filter - Merge",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [0.7333329916, 0.678430974483, 0.745097994804, 1],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Shape",
                    np: 5,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 3,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [944.855, 593.103, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-6.79, 0.19],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [6.78, -0.18],
                            ],
                            v: [
                              [10.88, 13.55],
                              [-0.87, -18.33],
                              [-10.88, 13.55],
                              [0.18, 18.32],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [1, 0.584313988686, 0.682353019714, 1],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 4,
                ty: 4,
                nm: "Oval",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [981.76, 577.323, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        d: 1,
                        ty: "el",
                        s: { a: 0, k: [52.289, 52.289], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        nm: "Ellipse Path 1",
                        mn: "ADBE Vector Shape - Ellipse",
                        hd: false,
                      },
                      {
                        ty: "st",
                        c: {
                          a: 0,
                          k: [0.93725502491, 0.956862986088, 1, 1],
                          ix: 3,
                        },
                        o: { a: 0, k: 100, ix: 4 },
                        w: { a: 0, k: 5.95, ix: 5 },
                        lc: 1,
                        lj: 1,
                        ml: 4,
                        ml2: { a: 0, k: 4, ix: 8 },
                        nm: "Stroke 1",
                        mn: "ADBE Vector Graphic - Stroke",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Oval",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 5,
                ty: 4,
                nm: "Oval",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [905.577, 577.323, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        d: 1,
                        ty: "el",
                        s: { a: 0, k: [52.289, 52.289], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        nm: "Ellipse Path 1",
                        mn: "ADBE Vector Shape - Ellipse",
                        hd: false,
                      },
                      {
                        ty: "st",
                        c: {
                          a: 0,
                          k: [0.93725502491, 0.956862986088, 1, 1],
                          ix: 3,
                        },
                        o: { a: 0, k: 100, ix: 4 },
                        w: { a: 0, k: 5.95, ix: 5 },
                        lc: 1,
                        lj: 1,
                        ml: 4,
                        ml2: { a: 0, k: 4, ix: 8 },
                        nm: "Stroke 1",
                        mn: "ADBE Vector Graphic - Stroke",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Oval",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 6,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [990.692, 584.039, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [12.743, -19.15],
                              [16.573, -15.17],
                              [-11.257, 19.15],
                              [-16.577, 15.33],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: { a: 0, k: [1, 1, 1, 0.391999989748], ix: 4 },
                        o: { a: 0, k: 39, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 7,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [975.982, 573.309, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [7.167, -21.852],
                              [19.846, -17.942],
                              [-12.204, 21.848],
                              [-19.844, 10.148],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: { a: 0, k: [1, 1, 1, 0.391999989748], ix: 4 },
                        o: { a: 0, k: 39, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 8,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [913.911, 586.014, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [12.743, -19.15],
                              [16.574, -15.171],
                              [-11.257, 19.15],
                              [-16.577, 15.329],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: { a: 0, k: [1, 1, 1, 0.391999989748], ix: 4 },
                        o: { a: 0, k: 39, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 9,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [900.134, 574.441, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [6.233, -21.01],
                              [20.773, -21.01],
                              [-12.087, 21.01],
                              [-20.777, 10.99],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: { a: 0, k: [1, 1, 1, 0.391999989748], ix: 4 },
                        o: { a: 0, k: 39, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 10,
                ty: 4,
                nm: "Shape",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [946.933, 636.766, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0.6, -0.56],
                              [-0.56, -0.6],
                            ],
                            o: [
                              [-0.56, -0.61],
                              [-0.61, 0.56],
                              [0, 0],
                            ],
                            v: [
                              [-10.93, -2.92],
                              [-13.03, -3],
                              [-13.11, -0.9],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ind: 1,
                        ty: "sh",
                        ix: 2,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0.55, 0.61],
                              [0.61, -0.56],
                            ],
                            o: [
                              [0.61, -0.55],
                              [-0.55, -0.61],
                              [0, 0],
                            ],
                            v: [
                              [13.022, -1.464],
                              [13.122, -3.564],
                              [11.022, -3.664],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 2",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ind: 2,
                        ty: "sh",
                        ix: 3,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [-4.76, 0.26],
                              [-3.59, 3.26],
                              [0, 0],
                              [4.07, -0.22],
                              [2.75, 2.96],
                            ],
                            o: [
                              [3.46, 3.73],
                              [4.74, -0.26],
                              [0, 0],
                              [-3.08, 2.79],
                              [-4.05, 0.22],
                              [0, 0],
                            ],
                            v: [
                              [-13.114, -0.896],
                              [-0.084, 4.024],
                              [13.026, -1.466],
                              [11.026, -3.666],
                              [-0.254, 1.054],
                              [-10.934, -2.926],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 3",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "mm",
                        mm: 1,
                        nm: "Merge Paths 1",
                        mn: "ADBE Vector Filter - Merge",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [1, 0.584313988686, 0.682353019714, 1],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Shape",
                    np: 5,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 11,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [961.234, 462.192, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [-4.14, -1.53],
                              [0, 0],
                              [-1.97, -0.2],
                              [0, 0],
                              [0, 0],
                              [7.45, -6.83],
                              [0, 0],
                              [4.34, 2.13],
                              [0, 0],
                              [3.66, 0.39],
                              [0, 0],
                              [-3.97, 5.95],
                            ],
                            o: [
                              [2.44, -3.68],
                              [0, 0],
                              [1.86, 0.69],
                              [0, 0],
                              [0, 0],
                              [10.05, 1.06],
                              [0, 0],
                              [-3.57, 3.27],
                              [0, 0],
                              [-3.3, -1.63],
                              [0, 0],
                              [-7.12, -0.75],
                              [0, 0],
                            ],
                            v: [
                              [-45.035, -13.202],
                              [-33.805, -16.862],
                              [-23.245, -12.942],
                              [-17.475, -11.592],
                              [22.015, -7.432],
                              [36.125, -5.952],
                              [42.825, 14.368],
                              [42.825, 14.368],
                              [29.715, 16.248],
                              [15.165, 9.078],
                              [4.635, 6.028],
                              [-38.105, 1.538],
                              [-45.035, -13.202],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [1, 0.007842999883, 0.388235002756, 1],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 12,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [940.618, 518.241, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, -37.32],
                              [6.81, -3.35],
                              [29.25, 25.87],
                              [16.87, 11.34],
                              [0, 17.89],
                              [-37.32, 0],
                            ],
                            o: [
                              [0, 17.75],
                              [-7.51, 3.7],
                              [-63.04, 43.77],
                              [-17.27, -24.9],
                              [0, -37.32],
                              [37.32, 0],
                            ],
                            v: [
                              [86.24, 5.399],
                              [78.46, 47.469],
                              [44.27, 15.119],
                              [-73.72, 57.919],
                              [-85.37, 5.399],
                              [1.51, -62.171],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [0.427451014519, 0.21960799396, 0.815685987473, 1],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 13,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [966.906, 428.048, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [3.84, -22.18],
                              [22.18, 3.84],
                              [-3.84, 22.18],
                              [-22.17, -3.84],
                            ],
                            o: [
                              [-3.84, 22.18],
                              [-22.18, -3.85],
                              [3.84, -22.18],
                              [22.18, 3.84],
                            ],
                            v: [
                              [46.403, 10.758],
                              [-6.457, 33.178],
                              [-46.597, -5.342],
                              [14.093, -33.642],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [0.427451014519, 0.21960799396, 0.815685987473, 1],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 14,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [943.666, 598.33, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, -19.31],
                              [39.84, -3.57],
                              [0, 37.9],
                              [-34.81, 0],
                              [-13.08, -6.97],
                            ],
                            o: [
                              [0, 37.91],
                              [-38.82, -11.75],
                              [0, -37.91],
                              [17.07, 0],
                              [13.58, 7.23],
                            ],
                            v: [
                              [70.961, -27.411],
                              [-0.139, 70.829],
                              [-74.199, -32.851],
                              [-3.189, -64.871],
                              [66.891, -68.451],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.996078014374,
                            0.800000011921,
                            0.823529005051,
                            1,
                          ],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 15,
                ty: 4,
                nm: "Oval",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1013.567, 588.437, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [-100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        d: 1,
                        ty: "el",
                        s: { a: 0, k: [25.785, 50.681], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        nm: "Ellipse Path 1",
                        mn: "ADBE Vector Shape - Ellipse",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [0, 0.05490199849, 0.294117987156, 1],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: -16.193, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Oval",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 16,
                ty: 4,
                nm: "Oval",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [873.621, 588.437, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        d: 1,
                        ty: "el",
                        s: { a: 0, k: [25.785, 50.681], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        nm: "Ellipse Path 1",
                        mn: "ADBE Vector Shape - Ellipse",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [0, 0.05490199849, 0.294117987156, 1],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: -16.193, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Oval",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 17,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [944.21, 722.816, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, -24.21],
                              [21.24, 0],
                              [0, 24.21],
                              [-21.24, 0],
                            ],
                            o: [
                              [0, 24.21],
                              [-21.24, 0],
                              [5.22, 0],
                              [21.24, 0],
                            ],
                            v: [
                              [38.628, -15.39],
                              [0.178, 28.45],
                              [-38.632, -15.39],
                              [-1.142, -22.73],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [1, 0.419607996941, 0.580392003059, 1],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 18,
                ty: 4,
                nm: "Oval",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        n: "0p667_1_0p333_0",
                        t: 10,
                        s: [1299.427, 594.456, 0],
                        e: [1291.285, 574.748, 0],
                        to: [-1.37645506858826, -3.07891273498535, 0],
                        ti: [-14.5437240600586, 9.32424449920654, 0],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        n: "0p667_1_0p333_0",
                        t: 40,
                        s: [1291.285, 574.748, 0],
                        e: [1351.221, 548.442, 0],
                        to: [13.5426225662231, -8.6824197769165, 0],
                        ti: [3.15469574928284, 7.056556224823, 0],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        n: "0p667_1_0p333_0",
                        t: 70,
                        s: [1351.221, 548.442, 0],
                        e: [1281.881, 508.848, 0],
                        to: [-3.08168792724609, -6.89324951171875, 0],
                        ti: [13.2804689407349, 4.93344783782959, 0],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        n: "0p667_1_0p333_0",
                        t: 100,
                        s: [1281.881, 508.848, 0],
                        e: [1234.08, 553.762, 0],
                        to: [-5.16334009170532, -1.91808497905731, 0],
                        ti: [2.82208657264709, 0.26418736577034, 0],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        n: "0p667_1_0p333_0",
                        t: 130,
                        s: [1234.08, 553.762, 0],
                        e: [1292.427, 541.456, 0],
                        to: [-11.2257089614868, -1.05088579654694, 0],
                        ti: [1.93633794784546, 4.33128213882446, 0],
                      },
                      { t: 160 },
                    ],
                    ix: 2,
                  },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                        o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                        n: [
                          "0p667_1_0p333_0",
                          "0p667_1_0p333_0",
                          "0p667_1_0p333_0",
                        ],
                        t: 25,
                        s: [0, 0, 100],
                        e: [100, 100, 100],
                      },
                      { t: 30 },
                    ],
                    ix: 6,
                  },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        d: 1,
                        ty: "el",
                        s: { a: 0, k: [30.566, 30.566], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        nm: "Ellipse Path 1",
                        mn: "ADBE Vector Shape - Ellipse",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.164706006646,
                            0.027450999245,
                            0.439215987921,
                            1,
                          ],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Oval",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 19,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        n: "0p667_1_0p333_0",
                        t: 10,
                        s: [575.053, 441.995, 0],
                        e: [562.053, 515.995, 0],
                        to: [-2.16666674613953, 12.3333330154419, 0],
                        ti: [-9.16666698455811, -10.1666669845581, 0],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        n: "0p667_1_0p333_0",
                        t: 40,
                        s: [562.053, 515.995, 0],
                        e: [630.053, 502.995, 0],
                        to: [9.16666698455811, 10.1666669845581, 0],
                        ti: [-17.1666660308838, 12.8333330154419, 0],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        n: "0p667_1_0p333_0",
                        t: 70,
                        s: [630.053, 502.995, 0],
                        e: [665.053, 438.995, 0],
                        to: [17.1666660308838, -12.8333330154419, 0],
                        ti: [-12.1666669845581, 5.33333349227905, 0],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        n: "0p667_1_0p333_0",
                        t: 100,
                        s: [665.053, 438.995, 0],
                        e: [703.053, 470.995, 0],
                        to: [12.1666669845581, -5.33333349227905, 0],
                        ti: [15, -0.5, 0],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        n: "0p667_1_0p333_0",
                        t: 130,
                        s: [703.053, 470.995, 0],
                        e: [575.053, 441.995, 0],
                        to: [-15, 0.5, 0],
                        ti: [21.3333339691162, 4.83333349227905, 0],
                      },
                      { t: 160 },
                    ],
                    ix: 2,
                  },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                        o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                        n: [
                          "0p667_1_0p333_0",
                          "0p667_1_0p333_0",
                          "0p667_1_0p333_0",
                        ],
                        t: 0,
                        s: [0, 0, 100],
                        e: [100, 100, 100],
                      },
                      { t: 5 },
                    ],
                    ix: 6,
                  },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, -8.44],
                              [8.44, 0],
                              [0, 8.44],
                              [-8.44, 0],
                            ],
                            o: [
                              [0, 8.44],
                              [-8.44, 0],
                              [0, -8.44],
                              [8.44, 0],
                            ],
                            v: [
                              [15.287, -0.003],
                              [-0.003, 15.287],
                              [-15.283, -0.003],
                              [-0.003, -15.283],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.164706006646,
                            0.027450999245,
                            0.439215987921,
                            1,
                          ],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 20,
                ty: 4,
                nm: "Rectangle",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: {
                    a: 0,
                    k: [1268.269, 332.419, 0],
                    ix: 2,
                    x:
                      "var $bm_rt;\n$bm_rt = thisComp.layer('Oval 2').transform.position;",
                  },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: {
                    a: 0,
                    k: [100, 100, 100],
                    ix: 6,
                    x:
                      "var $bm_rt;\n$bm_rt = thisComp.layer('Oval 2').transform.scale;",
                  },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ty: "rc",
                        d: 1,
                        s: { a: 0, k: [20.66, 20.66], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        r: { a: 0, k: 0, ix: 4 },
                        nm: "Rectangle Path 1",
                        mn: "ADBE Vector Shape - Rect",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.164706006646,
                            0.027450999245,
                            0.439215987921,
                            1,
                          ],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 30, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Rectangle",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 21,
                ty: 4,
                nm: "Rectangle",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: {
                    a: 0,
                    k: [951, 234.589, 0],
                    ix: 2,
                    x:
                      "var $bm_rt;\n$bm_rt = thisComp.layer('Rectangle 3').transform.position;",
                  },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: {
                    a: 0,
                    k: [100, 100, 100],
                    ix: 6,
                    x:
                      "var $bm_rt;\n$bm_rt = thisComp.layer('Rectangle 3').transform.scale;",
                  },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ty: "rc",
                        d: 1,
                        s: { a: 0, k: [20.66, 20.66], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        r: { a: 0, k: 0, ix: 4 },
                        nm: "Rectangle Path 1",
                        mn: "ADBE Vector Shape - Rect",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.164706006646,
                            0.027450999245,
                            0.439215987921,
                            1,
                          ],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Rectangle",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 22,
                ty: 4,
                nm: "Rectangle",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: {
                    a: 0,
                    k: [219.512, -213.616, 0],
                    ix: 2,
                    x:
                      "var $bm_rt;\n$bm_rt = thisComp.layer('Rectangle 2').transform.position;",
                  },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: {
                    a: 0,
                    k: [100, 100, 100],
                    ix: 6,
                    x:
                      "var $bm_rt;\n$bm_rt = thisComp.layer('Rectangle 2').transform.scale;",
                  },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ty: "rc",
                        d: 1,
                        s: { a: 0, k: [20.66, 20.66], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        r: { a: 0, k: 0, ix: 4 },
                        nm: "Rectangle Path 1",
                        mn: "ADBE Vector Shape - Rect",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.164706006646,
                            0.027450999245,
                            0.439215987921,
                            1,
                          ],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: -20.446, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Rectangle",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 23,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [936.277, 392.433, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 1,
                          k: [
                            {
                              i: { x: 0.667, y: 1 },
                              o: { x: 0.333, y: 0 },
                              n: "0p667_1_0p333_0",
                              t: 10,
                              s: [
                                {
                                  i: [
                                    [0, 0],
                                    [48.85, 63.77],
                                    [-168.92, -3.79],
                                    [495.09, 75.49],
                                  ],
                                  o: [
                                    [-23.47, 82.74],
                                    [-118.11, -154.2],
                                    [245.84, 5.5],
                                    [0, 0],
                                  ],
                                  v: [
                                    [-362.574, 50.551],
                                    [-505.334, 131.041],
                                    [12.926, -157.369],
                                    [326.966, 121.101],
                                  ],
                                  c: false,
                                },
                              ],
                              e: [
                                {
                                  i: [
                                    [0, 0],
                                    [48.85, 63.77],
                                    [-168.92, -3.79],
                                    [495.09, 75.49],
                                  ],
                                  o: [
                                    [-11.703, 25.015],
                                    [-118.11, -154.2],
                                    [245.84, 5.5],
                                    [0, 0],
                                  ],
                                  v: [
                                    [-373.574, 124.551],
                                    [-505.334, 131.041],
                                    [12.926, -157.369],
                                    [350.966, 185.101],
                                  ],
                                  c: false,
                                },
                              ],
                            },
                            {
                              i: { x: 0.667, y: 1 },
                              o: { x: 0.333, y: 0 },
                              n: "0p667_1_0p333_0",
                              t: 40,
                              s: [
                                {
                                  i: [
                                    [0, 0],
                                    [48.85, 63.77],
                                    [-168.92, -3.79],
                                    [495.09, 75.49],
                                  ],
                                  o: [
                                    [-11.703, 25.015],
                                    [-118.11, -154.2],
                                    [245.84, 5.5],
                                    [0, 0],
                                  ],
                                  v: [
                                    [-373.574, 124.551],
                                    [-505.334, 131.041],
                                    [12.926, -157.369],
                                    [350.966, 185.101],
                                  ],
                                  c: false,
                                },
                              ],
                              e: [
                                {
                                  i: [
                                    [0, 0],
                                    [48.85, 63.77],
                                    [-168.92, -3.79],
                                    [495.09, 75.49],
                                  ],
                                  o: [
                                    [-11.703, 25.015],
                                    [-118.11, -154.2],
                                    [245.84, 5.5],
                                    [0, 0],
                                  ],
                                  v: [
                                    [-307.574, 111.551],
                                    [-505.334, 131.041],
                                    [12.926, -157.369],
                                    [410.966, 156.101],
                                  ],
                                  c: false,
                                },
                              ],
                            },
                            {
                              i: { x: 0.667, y: 1 },
                              o: { x: 0.333, y: 0 },
                              n: "0p667_1_0p333_0",
                              t: 70,
                              s: [
                                {
                                  i: [
                                    [0, 0],
                                    [48.85, 63.77],
                                    [-168.92, -3.79],
                                    [495.09, 75.49],
                                  ],
                                  o: [
                                    [-11.703, 25.015],
                                    [-118.11, -154.2],
                                    [245.84, 5.5],
                                    [0, 0],
                                  ],
                                  v: [
                                    [-307.574, 111.551],
                                    [-505.334, 131.041],
                                    [12.926, -157.369],
                                    [410.966, 156.101],
                                  ],
                                  c: false,
                                },
                              ],
                              e: [
                                {
                                  i: [
                                    [0, 0],
                                    [48.85, 63.77],
                                    [-168.92, -3.79],
                                    [495.09, 75.49],
                                  ],
                                  o: [
                                    [-11.703, 25.015],
                                    [-118.11, -154.2],
                                    [245.84, 5.5],
                                    [0, 0],
                                  ],
                                  v: [
                                    [-272.574, 50.551],
                                    [-505.334, 131.041],
                                    [12.926, -157.369],
                                    [340.966, 117.101],
                                  ],
                                  c: false,
                                },
                              ],
                            },
                            {
                              i: { x: 0.667, y: 1 },
                              o: { x: 0.333, y: 0 },
                              n: "0p667_1_0p333_0",
                              t: 100,
                              s: [
                                {
                                  i: [
                                    [0, 0],
                                    [48.85, 63.77],
                                    [-168.92, -3.79],
                                    [495.09, 75.49],
                                  ],
                                  o: [
                                    [-11.703, 25.015],
                                    [-118.11, -154.2],
                                    [245.84, 5.5],
                                    [0, 0],
                                  ],
                                  v: [
                                    [-272.574, 50.551],
                                    [-505.334, 131.041],
                                    [12.926, -157.369],
                                    [340.966, 117.101],
                                  ],
                                  c: false,
                                },
                              ],
                              e: [
                                {
                                  i: [
                                    [0, 0],
                                    [48.85, 63.77],
                                    [-168.92, -3.79],
                                    [495.09, 75.49],
                                  ],
                                  o: [
                                    [-11.703, 25.015],
                                    [-118.11, -154.2],
                                    [245.84, 5.5],
                                    [0, 0],
                                  ],
                                  v: [
                                    [-238.574, 78.551],
                                    [-505.334, 131.041],
                                    [12.926, -157.369],
                                    [288.966, 162.101],
                                  ],
                                  c: false,
                                },
                              ],
                            },
                            {
                              i: { x: 0.667, y: 1 },
                              o: { x: 0.333, y: 0 },
                              n: "0p667_1_0p333_0",
                              t: 130,
                              s: [
                                {
                                  i: [
                                    [0, 0],
                                    [48.85, 63.77],
                                    [-168.92, -3.79],
                                    [495.09, 75.49],
                                  ],
                                  o: [
                                    [-11.703, 25.015],
                                    [-118.11, -154.2],
                                    [245.84, 5.5],
                                    [0, 0],
                                  ],
                                  v: [
                                    [-238.574, 78.551],
                                    [-505.334, 131.041],
                                    [12.926, -157.369],
                                    [288.966, 162.101],
                                  ],
                                  c: false,
                                },
                              ],
                              e: [
                                {
                                  i: [
                                    [0, 0],
                                    [48.85, 63.77],
                                    [-168.92, -3.79],
                                    [495.09, 75.49],
                                  ],
                                  o: [
                                    [-23.47, 82.74],
                                    [-118.11, -154.2],
                                    [245.84, 5.5],
                                    [0, 0],
                                  ],
                                  v: [
                                    [-362.574, 50.551],
                                    [-505.334, 131.041],
                                    [12.926, -157.369],
                                    [353.966, 148.101],
                                  ],
                                  c: false,
                                },
                              ],
                            },
                            { t: 160 },
                          ],
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "st",
                        c: { a: 0, k: [0.98, 0.66, 0.81, 1], ix: 3 },
                        o: { a: 0, k: 100, ix: 4 },
                        w: { a: 0, k: 5.344, ix: 5 },
                        lc: 1,
                        lj: 1,
                        ml: 4,
                        ml2: { a: 0, k: 4, ix: 8 },
                        d: [
                          { n: "d", nm: "dash", v: { a: 0, k: 6.675, ix: 1 } },
                        ],
                        nm: "Stroke 1",
                        mn: "ADBE Vector Graphic - Stroke",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                  {
                    ty: "tm",
                    s: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.667], y: [1] },
                          o: { x: [0.333], y: [0] },
                          n: ["0p667_1_0p333_0"],
                          t: 155,
                          s: [0],
                          e: [100],
                        },
                        { t: 175 },
                      ],
                      ix: 1,
                    },
                    e: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.667], y: [1] },
                          o: { x: [0.333], y: [0] },
                          n: ["0p667_1_0p333_0"],
                          t: 0,
                          s: [0],
                          e: [100],
                        },
                        { t: 30 },
                      ],
                      ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: "Trim Paths 1",
                    mn: "ADBE Vector Filter - Trim",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 24,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [944.385, 655.096, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [5.27, 44.68],
                              [-4.85, -43.5],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [22.48, -13.52],
                              [7.57, 67.92],
                              [0, 0],
                            ],
                            v: [
                              [-38.456, 52.332],
                              [-30.725, -38.568],
                              [30.885, -23.318],
                              [38.454, 52.332],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [1, 0.419607996941, 0.580392003059, 1],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 25,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [941.141, 724.807, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [-25, 2.87],
                              [0, 29.93],
                            ],
                            o: [
                              [8.31, 49.47],
                              [39.91, -4.57],
                              [0, 0],
                            ],
                            v: [
                              [-53.264, -33.577],
                              [5.867, 36.673],
                              [53.266, -36.907],
                            ],
                            c: false,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "st",
                        c: {
                          a: 0,
                          k: [1, 0.007842999883, 0.388235002756, 1],
                          ix: 3,
                        },
                        o: { a: 0, k: 100, ix: 4 },
                        w: { a: 0, k: 1.5, ix: 5 },
                        lc: 2,
                        lj: 1,
                        ml: 4,
                        ml2: { a: 0, k: 4, ix: 8 },
                        nm: "Stroke 1",
                        mn: "ADBE Vector Graphic - Stroke",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 26,
                ty: 4,
                nm: "Rectangle 2",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.582, y: 0.582 },
                        o: { x: 0.167, y: 0.167 },
                        n: "0p582_0p582_0p167_0p167",
                        t: 50,
                        s: [580.426, 518.551, 0],
                        e: [428.666, 523.041, 0],
                        to: [-14.4700317382812, 19.7400512695312, 0],
                        ti: [48.8500061035156, 63.7699584960938, 0],
                      },
                      {
                        i: { x: 0.63, y: 0.63 },
                        o: { x: 0.26, y: 0.26 },
                        n: "0p63_0p63_0p26_0p26",
                        t: 62.582,
                        s: [428.666, 523.041, 0],
                        e: [946.926, 234.631, 0],
                        to: [-118.109985351562, -154.200073242188, 0],
                        ti: [-168.919982910156, -3.79000854492188, 0],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.413, y: 0.413 },
                        n: "0p833_0p833_0p413_0p413",
                        t: 111.951,
                        s: [946.926, 234.631, 0],
                        e: [1292.966, 542.101, 0],
                        to: [245.840026855469, 5.5, 0],
                        ti: [495.090087890625, 75.489990234375, 0],
                      },
                      { t: 173.0927734375 },
                    ],
                    ix: 2,
                  },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: [0.833, 0.833, 0.833],
                          y: [0.833, 0.833, 0.833],
                        },
                        o: {
                          x: [0.167, 0.167, 0.167],
                          y: [0.167, 0.167, 0.167],
                        },
                        n: [
                          "0p833_0p833_0p167_0p167",
                          "0p833_0p833_0p167_0p167",
                          "0p833_0p833_0p167_0p167",
                        ],
                        t: 50,
                        s: [0, 0, 100],
                        e: [100, 100, 100],
                      },
                      {
                        i: {
                          x: [0.833, 0.833, 0.833],
                          y: [0.833, 0.833, 0.833],
                        },
                        o: {
                          x: [0.167, 0.167, 0.167],
                          y: [0.167, 0.167, 0.167],
                        },
                        n: [
                          "0p833_0p833_0p167_0p167",
                          "0p833_0p833_0p167_0p167",
                          "0p833_0p833_0p167_0p167",
                        ],
                        t: 64.874,
                        s: [100, 100, 100],
                        e: [100, 100, 100],
                      },
                      {
                        i: {
                          x: [0.833, 0.833, 0.833],
                          y: [0.833, 0.833, 0.833],
                        },
                        o: {
                          x: [0.167, 0.167, 0.167],
                          y: [0.167, 0.167, 0.167],
                        },
                        n: [
                          "0p833_0p833_0p167_0p167",
                          "0p833_0p833_0p167_0p167",
                          "0p833_0p833_0p167_0p167",
                        ],
                        t: 159.53,
                        s: [100, 100, 100],
                        e: [0, 0, 100],
                      },
                      { t: 173.0537109375 },
                    ],
                    ix: 6,
                  },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ty: "rc",
                        d: 1,
                        s: { a: 0, k: [93.943, 93.943], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        r: { a: 0, k: 0, ix: 4 },
                        nm: "Rectangle Path 1",
                        mn: "ADBE Vector Shape - Rect",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [1, 0.70980399847, 0.61960798502, 1],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: -20.459, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Rectangle",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 27,
                ty: 4,
                nm: "Rectangle 3",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.576, y: 0.576 },
                        o: { x: 0.167, y: 0.167 },
                        n: "0p576_0p576_0p167_0p167",
                        t: 32.01,
                        s: [583.426, 465.551, 0],
                        e: [428.666, 523.041, 0],
                        to: [-23.4700317382812, 82.7400512695312, 0],
                        ti: [48.8500061035156, 63.7699584960938, 0],
                      },
                      {
                        i: { x: 0.637, y: 0.637 },
                        o: { x: 0.27, y: 0.27 },
                        n: "0p637_0p637_0p27_0p27",
                        t: 47.303,
                        s: [428.666, 523.041, 0],
                        e: [946.926, 234.631, 0],
                        to: [-118.109985351562, -154.200073242188, 0],
                        ti: [-168.919982910156, -3.79000854492188, 0],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.415, y: 0.415 },
                        n: "0p833_0p833_0p415_0p415",
                        t: 96.425,
                        s: [946.926, 234.631, 0],
                        e: [1287.966, 541.101, 0],
                        to: [245.840026855469, 5.5, 0],
                        ti: [495.090087890625, 75.489990234375, 0],
                      },
                      { t: 157.041015625 },
                    ],
                    ix: 2,
                  },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: [0.833, 0.833, 0.833],
                          y: [0.833, 0.833, 0.833],
                        },
                        o: {
                          x: [0.167, 0.167, 0.167],
                          y: [0.167, 0.167, 0.167],
                        },
                        n: [
                          "0p833_0p833_0p167_0p167",
                          "0p833_0p833_0p167_0p167",
                          "0p833_0p833_0p167_0p167",
                        ],
                        t: 32.01,
                        s: [0, 0, 100],
                        e: [100, 100, 100],
                      },
                      {
                        i: {
                          x: [0.833, 0.833, 0.833],
                          y: [0.833, 0.833, 0.833],
                        },
                        o: {
                          x: [0.167, 0.167, 0.167],
                          y: [0.167, 0.167, 0.167],
                        },
                        n: [
                          "0p833_0p833_0p167_0p167",
                          "0p833_0p833_0p167_0p167",
                          "0p833_0p833_0p167_0p167",
                        ],
                        t: 47.119,
                        s: [100, 100, 100],
                        e: [100, 100, 100],
                      },
                      {
                        i: {
                          x: [0.833, 0.833, 0.833],
                          y: [0.833, 0.833, 0.833],
                        },
                        o: {
                          x: [0.167, 0.167, 0.167],
                          y: [0.167, 0.167, 0.167],
                        },
                        n: [
                          "0p833_0p833_0p167_0p167",
                          "0p833_0p833_0p167_0p167",
                          "0p833_0p833_0p167_0p167",
                        ],
                        t: 143.265,
                        s: [100, 100, 100],
                        e: [0, 0, 100],
                      },
                      { t: 157 },
                    ],
                    ix: 6,
                  },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ty: "rc",
                        d: 1,
                        s: { a: 0, k: [118.345, 118.345], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        r: { a: 0, k: 48.099, ix: 4 },
                        nm: "Rectangle Path 1",
                        mn: "ADBE Vector Shape - Rect",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [1, 0.007842999883, 0.388235002756, 1],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Rectangle",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 28,
                ty: 4,
                nm: "Oval 2",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.576, y: 0.576 },
                        o: { x: 0.167, y: 0.167 },
                        n: "0p576_0p576_0p167_0p167",
                        t: 10,
                        s: [573.426, 442.551, 0],
                        e: [430.666, 523.041, 0],
                        to: [-23.4700317382812, 82.7400512695312, 0],
                        ti: [48.8500061035156, 63.7699584960938, 0],
                      },
                      {
                        i: { x: 0.637, y: 0.637 },
                        o: { x: 0.27, y: 0.27 },
                        n: "0p637_0p637_0p27_0p27",
                        t: 25.442,
                        s: [430.666, 523.041, 0],
                        e: [948.926, 234.631, 0],
                        to: [-118.109985351562, -154.200073242188, 0],
                        ti: [-168.919982910156, -3.79000854492188, 0],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.415, y: 0.415 },
                        n: "0p833_0p833_0p415_0p415",
                        t: 75.416,
                        s: [948.926, 234.631, 0],
                        e: [1230.966, 551.101, 0],
                        to: [245.840026855469, 5.5, 0],
                        ti: [495.090087890625, 75.489990234375, 0],
                      },
                      { t: 135.4716796875 },
                    ],
                    ix: 2,
                  },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: [0.833, 0.833, 0.833],
                          y: [0.833, 0.833, 0.833],
                        },
                        o: {
                          x: [0.167, 0.167, 0.167],
                          y: [0.167, 0.167, 0.167],
                        },
                        n: [
                          "0p833_0p833_0p167_0p167",
                          "0p833_0p833_0p167_0p167",
                          "0p833_0p833_0p167_0p167",
                        ],
                        t: 10.567,
                        s: [0, 0, 100],
                        e: [100, 100, 100],
                      },
                      {
                        i: {
                          x: [0.833, 0.833, 0.833],
                          y: [0.833, 0.833, 0.833],
                        },
                        o: {
                          x: [0.167, 0.167, 0.167],
                          y: [0.167, 0.167, 0.167],
                        },
                        n: [
                          "0p833_0p833_0p167_0p167",
                          "0p833_0p833_0p167_0p167",
                          "0p833_0p833_0p167_0p167",
                        ],
                        t: 25.729,
                        s: [100, 100, 100],
                        e: [100, 100, 100],
                      },
                      {
                        i: {
                          x: [0.833, 0.833, 0.833],
                          y: [0.833, 0.833, 0.833],
                        },
                        o: {
                          x: [0.167, 0.167, 0.167],
                          y: [0.167, 0.167, 0.167],
                        },
                        n: [
                          "0p833_0p833_0p167_0p167",
                          "0p833_0p833_0p167_0p167",
                          "0p833_0p833_0p167_0p167",
                        ],
                        t: 122.216,
                        s: [100, 100, 100],
                        e: [0, 0, 100],
                      },
                      { t: 136 },
                    ],
                    ix: 6,
                  },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        d: 1,
                        ty: "el",
                        s: { a: 0, k: [156.473, 156.473], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        nm: "Ellipse Path 1",
                        mn: "ADBE Vector Shape - Ellipse",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [0.968626976013, 0.40000000596, 0.396077990532, 1],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Oval",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 29,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [865.699, 889.208, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, -5.2],
                              [5.2, 0],
                              [0, 5.2],
                              [-5.2, 0],
                            ],
                            o: [
                              [0, 5.2],
                              [-5.2, 0],
                              [0, -5.2],
                              [5.2, 0],
                            ],
                            v: [
                              [9.41, 1.173],
                              [0, 8.233],
                              [-9.41, 1.173],
                              [0, -8.237],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.164706006646,
                            0.027450999245,
                            0.439215987921,
                            1,
                          ],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 30,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        n: ["0p833_0p833_0p167_0p167"],
                        t: 10,
                        s: [0],
                        e: [0],
                      },
                      {
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        n: ["0p833_0p833_0p167_0p167"],
                        t: 40,
                        s: [0],
                        e: [25],
                      },
                      {
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        n: ["0p833_0p833_0p167_0p167"],
                        t: 70,
                        s: [25],
                        e: [36],
                      },
                      {
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        n: ["0p833_0p833_0p167_0p167"],
                        t: 100,
                        s: [36],
                        e: [25],
                      },
                      {
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        n: ["0p833_0p833_0p167_0p167"],
                        t: 130,
                        s: [25],
                        e: [0],
                      },
                      { t: 160 },
                    ],
                    ix: 10,
                  },
                  p: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        n: "0p667_1_0p333_0",
                        t: 10,
                        s: [605.874, 663.363, 0],
                        e: [595.874, 710.363, 0],
                        to: [-1.66666662693024, 7.83333349227905, 0],
                        ti: [0.33333334326744, -4.5, 0],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        n: "0p667_1_0p333_0",
                        t: 40,
                        s: [595.874, 710.363, 0],
                        e: [603.874, 690.363, 0],
                        to: [-0.33333334326744, 4.5, 0],
                        ti: [-6.66666650772095, 13.1666669845581, 0],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        n: "0p667_1_0p333_0",
                        t: 70,
                        s: [603.874, 690.363, 0],
                        e: [635.874, 631.363, 0],
                        to: [6.66666650772095, -13.1666669845581, 0],
                        ti: [-5.83333349227905, 2.16666674613953, 0],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        n: "0p667_1_0p333_0",
                        t: 100,
                        s: [635.874, 631.363, 0],
                        e: [638.874, 677.363, 0],
                        to: [5.83333349227905, -2.16666674613953, 0],
                        ti: [5, -5.33333349227905, 0],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        n: "0p667_1_0p333_0",
                        t: 130,
                        s: [638.874, 677.363, 0],
                        e: [605.874, 663.363, 0],
                        to: [-5, 5.33333349227905, 0],
                        ti: [5.5, 2.33333325386047, 0],
                      },
                      { t: 160 },
                    ],
                    ix: 2,
                  },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [-2.09, -4.76],
                              [4.75, -2.09],
                              [2.09, 4.76],
                              [-4.76, 2.09],
                            ],
                            o: [
                              [2.09, 4.76],
                              [-4.76, 2.09],
                              [-2.1, -4.75],
                              [4.76, -2.09],
                            ],
                            v: [
                              [7.102, -3.177],
                              [11.972, 14.183],
                              [-8.098, 5.263],
                              [-11.788, -14.267],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.164706006646,
                            0.027450999245,
                            0.439215987921,
                            1,
                          ],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 31,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        n: "0p667_1_0p333_0",
                        t: 10,
                        s: [1190.786, 741.447, 0],
                        e: [1190.786, 758.447, 0],
                        to: [0, 2.83333325386047, 0],
                        ti: [0, -3.66666674613953, 0],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        n: "0p667_1_0p333_0",
                        t: 40,
                        s: [1190.786, 758.447, 0],
                        e: [1190.786, 763.447, 0],
                        to: [0, 3.66666674613953, 0],
                        ti: [0.04166666790843, 2.83333325386047, 0],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        n: "0p667_1_0p333_0",
                        t: 70,
                        s: [1190.786, 763.447, 0],
                        e: [1190.536, 741.447, 0],
                        to: [-0.04166666790843, -2.83333325386047, 0],
                        ti: [3.04166674613953, 1.45833337306976, 0],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        n: "0p667_1_0p333_0",
                        t: 100,
                        s: [1190.536, 741.447, 0],
                        e: [1172.536, 754.697, 0],
                        to: [-3.04166674613953, -1.45833337306976, 0],
                        ti: [-0.04166666790843, 0, 0],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        n: "0p667_1_0p333_0",
                        t: 130,
                        s: [1172.536, 754.697, 0],
                        e: [1190.786, 741.447, 0],
                        to: [0.04166666790843, 0, 0],
                        ti: [-3.04166674613953, 2.20833325386047, 0],
                      },
                      { t: 160 },
                    ],
                    ix: 2,
                  },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, -5.2],
                              [5.2, 0],
                              [0, 5.19],
                              [-5.2, 0],
                            ],
                            o: [
                              [0, 5.19],
                              [-5.19, 0],
                              [0, -5.2],
                              [5.2, 0],
                            ],
                            v: [
                              [8.653, 0.411],
                              [-0.757, 9.821],
                              [-8.657, 2.021],
                              [-2.447, -9.819],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.164706006646,
                            0.027450999245,
                            0.439215987921,
                            1,
                          ],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 32,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [919.37, 765.573, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [-55.73, -13.14],
                              [-79.14, 18.1],
                              [-2.29, -3.19],
                              [0, 0],
                              [56.6, 13.34],
                              [14.58, 11.41],
                              [-1.28, 1.63],
                              [-1.63, -1.28],
                            ],
                            o: [
                              [67.05, 17.6],
                              [3.82, -0.87],
                              [0, 0],
                              [-83.8, 20.03],
                              [-56.5, -13.31],
                              [-1.63, -1.27],
                              [1.27, -1.63],
                              [13.52, 10.58],
                            ],
                            v: [
                              [-49.524, 11.699],
                              [153.036, 14.049],
                              [163.146, 17.839],
                              [163.146, 17.839],
                              [-40.394, 24.069],
                              [-161.704, -26.861],
                              [-162.344, -32.121],
                              [-157.084, -32.761],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.164706006646,
                            0.027450999245,
                            0.439215987921,
                            1,
                          ],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 33,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1008.197, 853.763, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [-57.18, -3.02],
                              [-74.68, 31.87],
                              [-2.81, -2.73],
                              [0, 0],
                              [58.07, 3.08],
                              [16.38, 8.64],
                              [-0.96, 1.83],
                              [-1.83, -0.97],
                            ],
                            o: [
                              [69.11, 5.43],
                              [3.6, -1.54],
                              [0, 0],
                              [-78.9, 34.6],
                              [-57.97, -3.07],
                              [-1.83, -0.96],
                              [0.97, -1.84],
                              [15.19, 8.01],
                            ],
                            v: [
                              [-45.562, 11.684],
                              [154.198, -21.956],
                              [164.808, -20.026],
                              [164.808, -20.026],
                              [-34.382, 22.244],
                              [-162.812, -6.336],
                              [-164.382, -11.396],
                              [-159.312, -12.966],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.164706006646,
                            0.027450999245,
                            0.439215987921,
                            1,
                          ],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 34,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [573.387, 206.221, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [-90.97, 41.91],
                            ],
                            o: [
                              [18.74, -23.85],
                              [0, 0],
                            ],
                            v: [
                              [-84.967, 61.971],
                              [84.963, -61.969],
                            ],
                            c: false,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "st",
                        c: {
                          a: 0,
                          k: [
                            0.949020028114,
                            0.949020028114,
                            0.949020028114,
                            1,
                          ],
                          ix: 3,
                        },
                        o: { a: 0, k: 100, ix: 4 },
                        w: { a: 0, k: 1.5, ix: 5 },
                        lc: 1,
                        lj: 1,
                        ml: 4,
                        ml2: { a: 0, k: 4, ix: 8 },
                        nm: "Stroke 1",
                        mn: "ADBE Vector Graphic - Stroke",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 35,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1467.381, 259.479, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [-0.27, -33.32],
                              [3.43, -9.62],
                            ],
                            o: [
                              [26.5, 3.23],
                              [0.27, 33.31],
                              [0, 0],
                            ],
                            v: [
                              [-39.92, -60.919],
                              [39.91, -9.589],
                              [29.95, 60.921],
                            ],
                            c: false,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "st",
                        c: {
                          a: 0,
                          k: [
                            0.949020028114,
                            0.949020028114,
                            0.949020028114,
                            1,
                          ],
                          ix: 3,
                        },
                        o: { a: 0, k: 100, ix: 4 },
                        w: { a: 0, k: 1.5, ix: 5 },
                        lc: 1,
                        lj: 1,
                        ml: 4,
                        ml2: { a: 0, k: 4, ix: 8 },
                        nm: "Stroke 1",
                        mn: "ADBE Vector Graphic - Stroke",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 36,
                ty: 4,
                nm: "Shape",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [793.344, 347.167, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0.01, 0.42],
                              [0.42, -0.01],
                            ],
                            o: [
                              [0.41, -0.02],
                              [-0.02, -0.41],
                              [0, 0],
                            ],
                            v: [
                              [61.444, -10.339],
                              [62.164, -11.119],
                              [61.384, -11.839],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ind: 1,
                        ty: "sh",
                        ix: 2,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [-49.94, 1.87],
                              [0, 0],
                              [20.31, -6.64],
                            ],
                            o: [
                              [20.22, -6.62],
                              [0, 0],
                              [-50.13, 1.87],
                              [0, 0],
                            ],
                            v: [
                              [-61.693, 11.842],
                              [61.437, -10.338],
                              [61.387, -11.838],
                              [-62.163, 10.412],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 2",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "mm",
                        mm: 1,
                        nm: "Merge Paths 1",
                        mn: "ADBE Vector Filter - Merge",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [0.7333329916, 0.678430974483, 0.745097994804, 1],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Shape",
                    np: 4,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 37,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [963.069, 846.717, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [20.93, 128.63],
                              [0, 0],
                              [38.23, -54.16],
                              [57, 9],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [-38.23, 54.16],
                              [-57, -9],
                            ],
                            v: [
                              [-213.462, -98.17],
                              [213.458, -71.57],
                              [134.488, 56.4],
                              [-66.672, 90.78],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [0.427451014519, 0.21960799396, 0.815685987473, 1],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 38,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [945.743, 642.972, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 1,
                          k: [
                            {
                              i: { x: 0.667, y: 1 },
                              o: { x: 0.333, y: 0 },
                              n: "0p667_1_0p333_0",
                              t: 10,
                              s: [
                                {
                                  i: [
                                    [0, 0],
                                    [40.64, -6.44],
                                    [48.455, 7.77],
                                    [60.849, -9.359],
                                    [49.342, 10.978],
                                    [31.18, 152.677],
                                  ],
                                  o: [
                                    [23.08, 88.56],
                                    [-100.286, 15.899],
                                    [-50.382, -8.079],
                                    [-23.305, 3.586],
                                    [-100.095, -22.27],
                                    [0, 0],
                                  ],
                                  v: [
                                    [356.674, -69.063],
                                    [351.684, 121.777],
                                    [143.651, 124.214],
                                    [-6.246, 115.167],
                                    [-122.279, 106.906],
                                    [-378.156, -131.913],
                                  ],
                                  c: false,
                                },
                              ],
                              e: [
                                {
                                  i: [
                                    [0, 0],
                                    [40.64, -6.44],
                                    [42.932, 4.772],
                                    [73.125, -11.247],
                                    [69.518, -4.439],
                                    [26.849, 131.468],
                                  ],
                                  o: [
                                    [23.08, 88.56],
                                    [-79.248, 12.564],
                                    [-67.887, -7.547],
                                    [-29.872, 4.596],
                                    [-108.647, 6.938],
                                    [0, 0],
                                  ],
                                  v: [
                                    [356.674, -29.063],
                                    [354.684, 148.777],
                                    [175.855, 128.65],
                                    [-6.246, 115.167],
                                    [-165.095, 95.09],
                                    [-380.156, -82.913],
                                  ],
                                  c: false,
                                },
                              ],
                            },
                            {
                              i: { x: 0.667, y: 1 },
                              o: { x: 0.333, y: 0 },
                              n: "0p667_1_0p333_0",
                              t: 40,
                              s: [
                                {
                                  i: [
                                    [0, 0],
                                    [40.64, -6.44],
                                    [42.932, 4.772],
                                    [73.125, -11.247],
                                    [69.518, -4.439],
                                    [26.849, 131.468],
                                  ],
                                  o: [
                                    [23.08, 88.56],
                                    [-79.248, 12.564],
                                    [-67.887, -7.547],
                                    [-29.872, 4.596],
                                    [-108.647, 6.938],
                                    [0, 0],
                                  ],
                                  v: [
                                    [356.674, -29.063],
                                    [354.684, 148.777],
                                    [175.855, 128.65],
                                    [-6.246, 115.167],
                                    [-165.095, 95.09],
                                    [-380.156, -82.913],
                                  ],
                                  c: false,
                                },
                              ],
                              e: [
                                {
                                  i: [
                                    [0, 0],
                                    [40.64, -6.44],
                                    [42.932, 4.772],
                                    [73.125, -11.247],
                                    [68.248, -13.953],
                                    [-29.586, 117.941],
                                  ],
                                  o: [
                                    [23.08, 88.56],
                                    [-79.248, 12.564],
                                    [-67.887, -7.547],
                                    [-29.872, 4.596],
                                    [-136.647, 27.938],
                                    [0, 0],
                                  ],
                                  v: [
                                    [415.674, -57.063],
                                    [335.684, 159.777],
                                    [175.855, 128.65],
                                    [-6.246, 115.167],
                                    [-165.095, 95.09],
                                    [-323.156, -97.913],
                                  ],
                                  c: false,
                                },
                              ],
                            },
                            {
                              i: { x: 0.667, y: 1 },
                              o: { x: 0.333, y: 0 },
                              n: "0p667_1_0p333_0",
                              t: 70,
                              s: [
                                {
                                  i: [
                                    [0, 0],
                                    [40.64, -6.44],
                                    [42.932, 4.772],
                                    [73.125, -11.247],
                                    [68.248, -13.953],
                                    [-29.586, 117.941],
                                  ],
                                  o: [
                                    [23.08, 88.56],
                                    [-79.248, 12.564],
                                    [-67.887, -7.547],
                                    [-29.872, 4.596],
                                    [-136.647, 27.938],
                                    [0, 0],
                                  ],
                                  v: [
                                    [415.674, -57.063],
                                    [335.684, 159.777],
                                    [175.855, 128.65],
                                    [-6.246, 115.167],
                                    [-165.095, 95.09],
                                    [-323.156, -97.913],
                                  ],
                                  c: false,
                                },
                              ],
                              e: [
                                {
                                  i: [
                                    [0, 0],
                                    [40.64, -6.44],
                                    [44.438, 5.59],
                                    [69.777, -10.732],
                                    [62.919, 8.541],
                                    [-13.014, 127.415],
                                  ],
                                  o: [
                                    [23.08, 88.56],
                                    [-84.985, 13.473],
                                    [-63.113, -7.692],
                                    [-28.081, 4.321],
                                    [-127.324, -17.285],
                                    [0, 0],
                                  ],
                                  v: [
                                    [342.583, -88.336],
                                    [317.047, 119.413],
                                    [167.072, 127.44],
                                    [-6.246, 115.167],
                                    [-153.418, 98.313],
                                    [-279.156, -166.186],
                                  ],
                                  c: false,
                                },
                              ],
                            },
                            {
                              i: { x: 0.667, y: 1 },
                              o: { x: 0.333, y: 0 },
                              n: "0p667_1_0p333_0",
                              t: 100,
                              s: [
                                {
                                  i: [
                                    [0, 0],
                                    [40.64, -6.44],
                                    [44.438, 5.59],
                                    [69.777, -10.732],
                                    [62.919, 8.541],
                                    [-13.014, 127.415],
                                  ],
                                  o: [
                                    [23.08, 88.56],
                                    [-84.985, 13.473],
                                    [-63.113, -7.692],
                                    [-28.081, 4.321],
                                    [-127.324, -17.285],
                                    [0, 0],
                                  ],
                                  v: [
                                    [342.583, -88.336],
                                    [317.047, 119.413],
                                    [167.072, 127.44],
                                    [-6.246, 115.167],
                                    [-153.418, 98.313],
                                    [-279.156, -166.186],
                                  ],
                                  c: false,
                                },
                              ],
                              e: [
                                {
                                  i: [
                                    [0, 0],
                                    [40.64, -6.44],
                                    [45.945, 6.408],
                                    [66.429, -10.217],
                                    [57.827, 9.455],
                                    [-46.461, 141.361],
                                  ],
                                  o: [
                                    [23.08, 88.56],
                                    [-90.723, 14.383],
                                    [-58.339, -7.837],
                                    [-26.29, 4.045],
                                    [-117.113, -19.154],
                                    [0, 0],
                                  ],
                                  v: [
                                    [293.867, -50.108],
                                    [303.036, 144.3],
                                    [158.289, 126.23],
                                    [-6.246, 115.167],
                                    [-141.741, 101.535],
                                    [-256.281, -135.333],
                                  ],
                                  c: false,
                                },
                              ],
                            },
                            {
                              i: { x: 0.667, y: 1 },
                              o: { x: 0.333, y: 0 },
                              n: "0p667_1_0p333_0",
                              t: 130,
                              s: [
                                {
                                  i: [
                                    [0, 0],
                                    [40.64, -6.44],
                                    [45.945, 6.408],
                                    [66.429, -10.217],
                                    [57.827, 9.455],
                                    [-46.461, 141.361],
                                  ],
                                  o: [
                                    [23.08, 88.56],
                                    [-90.723, 14.383],
                                    [-58.339, -7.837],
                                    [-26.29, 4.045],
                                    [-117.113, -19.154],
                                    [0, 0],
                                  ],
                                  v: [
                                    [293.867, -50.108],
                                    [303.036, 144.3],
                                    [158.289, 126.23],
                                    [-6.246, 115.167],
                                    [-141.741, 101.535],
                                    [-256.281, -135.333],
                                  ],
                                  c: false,
                                },
                              ],
                              e: [
                                {
                                  i: [
                                    [0, 0],
                                    [40.64, -6.44],
                                    [48.455, 7.77],
                                    [60.849, -9.359],
                                    [49.342, 10.978],
                                    [31.18, 152.677],
                                  ],
                                  o: [
                                    [23.08, 88.56],
                                    [-100.286, 15.899],
                                    [-50.382, -8.079],
                                    [-23.305, 3.586],
                                    [-100.095, -22.27],
                                    [0, 0],
                                  ],
                                  v: [
                                    [356.674, -69.063],
                                    [351.684, 121.777],
                                    [143.651, 124.214],
                                    [-6.246, 115.167],
                                    [-122.279, 106.906],
                                    [-378.156, -131.913],
                                  ],
                                  c: false,
                                },
                              ],
                            },
                            { t: 160 },
                          ],
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "st",
                        c: {
                          a: 0,
                          k: [0.427451014519, 0.21960799396, 0.815685987473, 1],
                          ix: 3,
                        },
                        o: { a: 0, k: 100, ix: 4 },
                        w: { a: 0, k: 112.231, ix: 5 },
                        lc: 1,
                        lj: 1,
                        ml: 4,
                        ml2: { a: 0, k: 4, ix: 8 },
                        nm: "Stroke 1",
                        mn: "ADBE Vector Graphic - Stroke",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 39,
                ty: 0,
                nm: "h2",
                refId: "comp_0",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 10,
                        s: [0],
                        e: [-8],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 40,
                        s: [-8],
                        e: [-8],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 70,
                        s: [-8],
                        e: [-3],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 100,
                        s: [-3],
                        e: [-3],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 130,
                        s: [-3],
                        e: [0],
                      },
                      { t: 160 },
                    ],
                    ix: 10,
                  },
                  p: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        n: "0p667_1_0p333_0",
                        t: 10,
                        s: [1291, 535, 0],
                        e: [1283, 569, 0],
                        to: [-1.33333337306976, 5.66666650772095, 0],
                        ti: [-9, -0.66666668653488, 0],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        n: "0p667_1_0p333_0",
                        t: 40,
                        s: [1283, 569, 0],
                        e: [1345, 539, 0],
                        to: [9, 0.66666668653488, 0],
                        ti: [2, 11, 0],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        n: "0p667_1_0p333_0",
                        t: 70,
                        s: [1345, 539, 0],
                        e: [1271, 503, 0],
                        to: [-2, -11, 0],
                        ti: [20.3333339691162, -0.66666668653488, 0],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        n: "0p667_1_0p333_0",
                        t: 100,
                        s: [1271, 503, 0],
                        e: [1223, 543, 0],
                        to: [-20.3333339691162, 0.66666668653488, 0],
                        ti: [-3.33333325386047, -5.33333349227905, 0],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        n: "0p667_1_0p333_0",
                        t: 130,
                        s: [1223, 543, 0],
                        e: [1291, 535, 0],
                        to: [3.33333325386047, 5.33333349227905, 0],
                        ti: [-11.3333330154419, 1.33333337306976, 0],
                      },
                      { t: 160 },
                    ],
                    ix: 2,
                  },
                  a: { a: 0, k: [52.5, 78, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                w: 105,
                h: 156,
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 40,
                ty: 0,
                nm: "h1",
                refId: "comp_1",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 10,
                        s: [0],
                        e: [11],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 40,
                        s: [11],
                        e: [27.25],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 70,
                        s: [27.25],
                        e: [25.167],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 100,
                        s: [25.167],
                        e: [33.583],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 130,
                        s: [33.583],
                        e: [0],
                      },
                      { t: 160 },
                    ],
                    ix: 10,
                  },
                  p: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        n: "0p667_1_0p333_0",
                        t: 10,
                        s: [557, 465, 0],
                        e: [565, 513, 0],
                        to: [1.33333337306976, 8, 0],
                        ti: [0, 0, 0],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        n: "0p667_1_0p333_0",
                        t: 40,
                        s: [565, 513, 0],
                        e: [635, 503, 0],
                        to: [0, 0, 0],
                        ti: [1.1518120765686, 6.91087293624878, 0],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        n: "0p667_1_0p333_0",
                        t: 70,
                        s: [635, 503, 0],
                        e: [672.922, 439.4, 0],
                        to: [-0.75415569543839, -4.5249342918396, 0],
                        ti: [12.950439453125, 5.50549507141113, 0],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        n: "0p667_1_0p333_0",
                        t: 100,
                        s: [672.922, 439.4, 0],
                        e: [706.198, 463.534, 0],
                        to: [-10.2722721099854, -4.36695194244385, 0],
                        ti: [23.8858375549316, -7.01735639572144, 0],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        n: "0p667_1_0p333_0",
                        t: 130,
                        s: [706.198, 463.534, 0],
                        e: [557, 465, 0],
                        to: [-28.9484272003174, 8.50468063354492, 0],
                        ti: [0.2993495464325, 1.79609727859497, 0],
                      },
                      { t: 160 },
                    ],
                    ix: 2,
                  },
                  a: { a: 0, k: [52.5, 89, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                w: 105,
                h: 178,
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 41,
                ty: 4,
                nm: "Rectangle",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1488.622, 536.673, 0], ix: 2 },
                  a: { a: 0, k: [205.017, 0, 0], ix: 1 },
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                        o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                        n: [
                          "0p667_1_0p333_0",
                          "0p667_1_0p333_0",
                          "0p667_1_0p333_0",
                        ],
                        t: 10,
                        s: [0, 100, 100],
                        e: [100, 100, 100],
                      },
                      {
                        i: { x: [0.567, 0.567, 0.567], y: [1, 1, 1] },
                        o: { x: [0.214, 0.214, 0.214], y: [0, 0, 0] },
                        n: [
                          "0p567_1_0p214_0",
                          "0p567_1_0p214_0",
                          "0p567_1_0p214_0",
                        ],
                        t: 25,
                        s: [100, 100, 100],
                        e: [57, 100, 100],
                      },
                      {
                        i: { x: [0.646, 0.646, 0.646], y: [0.881, 1, 1] },
                        o: { x: [0.287, 0.287, 0.287], y: [0, 0, 0] },
                        n: [
                          "0p646_0p881_0p287_0",
                          "0p646_1_0p287_0",
                          "0p646_1_0p287_0",
                        ],
                        t: 48,
                        s: [57, 100, 100],
                        e: [123.323, 100, 100],
                      },
                      {
                        i: { x: [0.695, 0.695, 0.695], y: [0.88, 1, 1] },
                        o: { x: [0.35, 0.35, 0.35], y: [-0.109, 0, 0] },
                        n: [
                          "0p695_0p88_0p35_-0p109",
                          "0p695_1_0p35_0",
                          "0p695_1_0p35_0",
                        ],
                        t: 96,
                        s: [123.323, 100, 100],
                        e: [72.875, 100, 100],
                      },
                      {
                        i: { x: [0.799, 0.799, 0.799], y: [1, 1, 1] },
                        o: { x: [0.437, 0.437, 0.437], y: [-0.284, 0, 0] },
                        n: [
                          "0p799_1_0p437_-0p284",
                          "0p799_1_0p437_0",
                          "0p799_1_0p437_0",
                        ],
                        t: 130,
                        s: [72.875, 100, 100],
                        e: [100, 100, 100],
                      },
                      {
                        i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                        o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                        n: [
                          "0p833_1_0p167_0",
                          "0p833_1_0p167_0",
                          "0p833_1_0p167_0",
                        ],
                        t: 160,
                        s: [100, 100, 100],
                        e: [0, 100, 100],
                      },
                      { t: 175 },
                    ],
                    ix: 6,
                  },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ty: "rc",
                        d: 1,
                        s: { a: 0, k: [410.034, 39.825], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        r: { a: 0, k: 0, ix: 4 },
                        nm: "Rectangle Path 1",
                        mn: "ADBE Vector Shape - Rect",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [1, 0.61960798502, 0.725489974022, 1],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Rectangle",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 42,
                ty: 4,
                nm: "Rectangle",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1488.623, 460.848, 0], ix: 2 },
                  a: { a: 0, k: [177.619, 0, 0], ix: 1 },
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                        o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                        n: [
                          "0p667_1_0p333_0",
                          "0p667_1_0p333_0",
                          "0p667_1_0p333_0",
                        ],
                        t: 5,
                        s: [0, 100, 100],
                        e: [100, 100, 100],
                      },
                      {
                        i: { x: [0.562, 0.562, 0.562], y: [1, 1, 1] },
                        o: { x: [0.194, 0.194, 0.194], y: [0, 0, 0] },
                        n: [
                          "0p562_1_0p194_0",
                          "0p562_1_0p194_0",
                          "0p562_1_0p194_0",
                        ],
                        t: 20,
                        s: [100, 100, 100],
                        e: [38, 100, 100],
                      },
                      {
                        i: { x: [0.656, 0.656, 0.656], y: [0.935, 1, 1] },
                        o: { x: [0.311, 0.311, 0.311], y: [0, 0, 0] },
                        n: [
                          "0p656_0p935_0p311_0",
                          "0p656_1_0p311_0",
                          "0p656_1_0p311_0",
                        ],
                        t: 55,
                        s: [38, 100, 100],
                        e: [151.528, 100, 100],
                      },
                      {
                        i: { x: [0.69, 0.69, 0.69], y: [0.861, 1, 1] },
                        o: { x: [0.345, 0.345, 0.345], y: [-0.092, 0, 0] },
                        n: [
                          "0p69_0p861_0p345_-0p092",
                          "0p69_1_0p345_0",
                          "0p69_1_0p345_0",
                        ],
                        t: 88,
                        s: [151.528, 100, 100],
                        e: [71.614, 100, 100],
                      },
                      {
                        i: { x: [0.805, 0.805, 0.805], y: [1, 1, 1] },
                        o: { x: [0.438, 0.438, 0.438], y: [-0.569, 0, 0] },
                        n: [
                          "0p805_1_0p438_-0p569",
                          "0p805_1_0p438_0",
                          "0p805_1_0p438_0",
                        ],
                        t: 121,
                        s: [71.614, 100, 100],
                        e: [100, 100, 100],
                      },
                      {
                        i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                        o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                        n: [
                          "0p833_1_0p167_0",
                          "0p833_1_0p167_0",
                          "0p833_1_0p167_0",
                        ],
                        t: 155,
                        s: [100, 100, 100],
                        e: [0, 100, 100],
                      },
                      { t: 170 },
                    ],
                    ix: 6,
                  },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ty: "rc",
                        d: 1,
                        s: { a: 0, k: [355.237, 39.825], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        r: { a: 0, k: 0, ix: 4 },
                        nm: "Rectangle Path 1",
                        mn: "ADBE Vector Shape - Rect",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [1, 0.61960798502, 0.725489974022, 1],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Rectangle",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 43,
                ty: 4,
                nm: "Rectangle",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1488.622, 385.023, 0], ix: 2 },
                  a: { a: 0, k: [344.324, 0, 0], ix: 1 },
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                        o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                        n: [
                          "0p667_1_0p333_0",
                          "0p667_1_0p333_0",
                          "0p667_1_0p333_0",
                        ],
                        t: 0,
                        s: [0, 100, 100],
                        e: [100, 100, 100],
                      },
                      {
                        i: { x: [0.649, 0.649, 0.649], y: [1, 1, 1] },
                        o: { x: [0.31, 0.31, 0.31], y: [0, 0, 0] },
                        n: [
                          "0p649_1_0p31_0",
                          "0p649_1_0p31_0",
                          "0p649_1_0p31_0",
                        ],
                        t: 15,
                        s: [100, 100, 100],
                        e: [120, 100, 100],
                      },
                      {
                        i: { x: [0.667, 0.667, 0.667], y: [0.965, 1, 1] },
                        o: { x: [0.326, 0.326, 0.326], y: [0, 0, 0] },
                        n: [
                          "0p667_0p965_0p326_0",
                          "0p667_1_0p326_0",
                          "0p667_1_0p326_0",
                        ],
                        t: 41,
                        s: [120, 100, 100],
                        e: [52.967, 100, 100],
                      },
                      {
                        i: { x: [0.709, 0.709, 0.709], y: [0.821, 1, 1] },
                        o: { x: [0.361, 0.361, 0.361], y: [-0.042, 0, 0] },
                        n: [
                          "0p709_0p821_0p361_-0p042",
                          "0p709_1_0p361_0",
                          "0p709_1_0p361_0",
                        ],
                        t: 78,
                        s: [52.967, 100, 100],
                        e: [127.847, 100, 100],
                      },
                      {
                        i: { x: [0.773, 0.773, 0.773], y: [1, 1, 1] },
                        o: { x: [0.423, 0.423, 0.423], y: [-0.419, 0, 0] },
                        n: [
                          "0p773_1_0p423_-0p419",
                          "0p773_1_0p423_0",
                          "0p773_1_0p423_0",
                        ],
                        t: 123,
                        s: [127.847, 100, 100],
                        e: [100, 100, 100],
                      },
                      {
                        i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                        o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                        n: [
                          "0p833_1_0p167_0",
                          "0p833_1_0p167_0",
                          "0p833_1_0p167_0",
                        ],
                        t: 150,
                        s: [100, 100, 100],
                        e: [0, 100, 100],
                      },
                      { t: 165 },
                    ],
                    ix: 6,
                  },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ty: "rc",
                        d: 1,
                        s: { a: 0, k: [688.648, 39.825], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        r: { a: 0, k: 0, ix: 4 },
                        nm: "Rectangle Path 1",
                        mn: "ADBE Vector Shape - Rect",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [1, 0.61960798502, 0.725489974022, 1],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Rectangle",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 44,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1254.154, 645.485, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [-151.87, -15.43],
                            ],
                            o: [
                              [175.77, 97.1],
                              [0, 0],
                            ],
                            v: [
                              [-220.085, -24.538],
                              [220.085, 8.322],
                            ],
                            c: false,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "st",
                        c: {
                          a: 0,
                          k: [
                            0.949020028114,
                            0.949020028114,
                            0.949020028114,
                            1,
                          ],
                          ix: 3,
                        },
                        o: { a: 0, k: 100, ix: 4 },
                        w: { a: 0, k: 1.5, ix: 5 },
                        lc: 2,
                        lj: 1,
                        ml: 4,
                        ml2: { a: 0, k: 4, ix: 8 },
                        nm: "Stroke 1",
                        mn: "ADBE Vector Graphic - Stroke",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 45,
                ty: 0,
                nm: "r",
                refId: "comp_2",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [632, 825, 0], ix: 2 },
                  a: { a: 0, k: [169, 30, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                w: 338,
                h: 60,
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 46,
                ty: 4,
                nm: "Rectangle",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [456.797, 744.241, 0], ix: 2 },
                  a: { a: 0, k: [-177.619, 0, 0], ix: 1 },
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                        o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                        n: [
                          "0p667_1_0p333_0",
                          "0p667_1_0p333_0",
                          "0p667_1_0p333_0",
                        ],
                        t: 10,
                        s: [0, 100, 100],
                        e: [100, 100, 100],
                      },
                      {
                        i: { x: [0.651, 0.651, 0.651], y: [1, 1, 1] },
                        o: { x: [0.314, 0.314, 0.314], y: [0, 0, 0] },
                        n: [
                          "0p651_1_0p314_0",
                          "0p651_1_0p314_0",
                          "0p651_1_0p314_0",
                        ],
                        t: 25,
                        s: [100, 100, 100],
                        e: [44, 100, 100],
                      },
                      {
                        i: { x: [0.665, 0.665, 0.665], y: [0.916, 1, 1] },
                        o: { x: [0.321, 0.321, 0.321], y: [0, 0, 0] },
                        n: [
                          "0p665_0p916_0p321_0",
                          "0p665_1_0p321_0",
                          "0p665_1_0p321_0",
                        ],
                        t: 45,
                        s: [44, 100, 100],
                        e: [134.084, 100, 100],
                      },
                      {
                        i: { x: [0.688, 0.688, 0.688], y: [1.082, 1, 1] },
                        o: { x: [0.348, 0.348, 0.348], y: [0.082, 0, 0] },
                        n: [
                          "0p688_1p082_0p348_0p082",
                          "0p688_1_0p348_0",
                          "0p688_1_0p348_0",
                        ],
                        t: 87,
                        s: [134.084, 100, 100],
                        e: [207.256, 100, 100],
                      },
                      {
                        i: { x: [0.793, 0.793, 0.793], y: [1, 1, 1] },
                        o: { x: [0.43, 0.43, 0.43], y: [0.099, 0, 0] },
                        n: [
                          "0p793_1_0p43_0p099",
                          "0p793_1_0p43_0",
                          "0p793_1_0p43_0",
                        ],
                        t: 119,
                        s: [207.256, 100, 100],
                        e: [100, 100, 100],
                      },
                      {
                        i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                        o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                        n: [
                          "0p833_1_0p167_0",
                          "0p833_1_0p167_0",
                          "0p833_1_0p167_0",
                        ],
                        t: 160,
                        s: [100, 100, 100],
                        e: [0, 100, 100],
                      },
                      { t: 175 },
                    ],
                    ix: 6,
                  },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ty: "rc",
                        d: 1,
                        s: { a: 0, k: [355.237, 39.825], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        r: { a: 0, k: 0, ix: 4 },
                        nm: "Rectangle Path 1",
                        mn: "ADBE Vector Shape - Rect",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [1, 0.61960798502, 0.725489974022, 1],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Rectangle",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 47,
                ty: 4,
                nm: "Rectangle",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [456.797, 680.416, 0], ix: 2 },
                  a: { a: 0, k: [-177.619, 0, 0], ix: 1 },
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                        o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                        n: [
                          "0p667_1_0p333_0",
                          "0p667_1_0p333_0",
                          "0p667_1_0p333_0",
                        ],
                        t: 5,
                        s: [0, 100, 100],
                        e: [100, 100, 100],
                      },
                      {
                        i: { x: [0.648, 0.648, 0.648], y: [1, 1, 1] },
                        o: { x: [0.307, 0.307, 0.307], y: [0, 0, 0] },
                        n: [
                          "0p648_1_0p307_0",
                          "0p648_1_0p307_0",
                          "0p648_1_0p307_0",
                        ],
                        t: 20,
                        s: [100, 100, 100],
                        e: [16, 100, 100],
                      },
                      {
                        i: { x: [0.674, 0.674, 0.674], y: [0.917, 1, 1] },
                        o: { x: [0.331, 0.331, 0.331], y: [0, 0, 0] },
                        n: [
                          "0p674_0p917_0p331_0",
                          "0p674_1_0p331_0",
                          "0p674_1_0p331_0",
                        ],
                        t: 53,
                        s: [16, 100, 100],
                        e: [170.364, 100, 100],
                      },
                      {
                        i: { x: [0.701, 0.701, 0.701], y: [0.757, 1, 1] },
                        o: { x: [0.359, 0.359, 0.359], y: [-0.152, 0, 0] },
                        n: [
                          "0p701_0p757_0p359_-0p152",
                          "0p701_1_0p359_0",
                          "0p701_1_0p359_0",
                        ],
                        t: 94,
                        s: [170.364, 100, 100],
                        e: [96.136, 100, 100],
                      },
                      {
                        i: { x: [0.775, 0.775, 0.775], y: [1, 1, 1] },
                        o: { x: [0.424, 0.424, 0.424], y: [-5.625, 0, 0] },
                        n: [
                          "0p775_1_0p424_-5p625",
                          "0p775_1_0p424_0",
                          "0p775_1_0p424_0",
                        ],
                        t: 127,
                        s: [96.136, 100, 100],
                        e: [100, 100, 100],
                      },
                      {
                        i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                        o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                        n: [
                          "0p833_1_0p167_0",
                          "0p833_1_0p167_0",
                          "0p833_1_0p167_0",
                        ],
                        t: 155,
                        s: [100, 100, 100],
                        e: [0, 100, 100],
                      },
                      { t: 170 },
                    ],
                    ix: 6,
                  },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ty: "rc",
                        d: 1,
                        s: { a: 0, k: [355.237, 39.825], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        r: { a: 0, k: 0, ix: 4 },
                        nm: "Rectangle Path 1",
                        mn: "ADBE Vector Shape - Rect",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [1, 0.61960798502, 0.725489974022, 1],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Rectangle",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 48,
                ty: 4,
                nm: "Rectangle",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [456.797, 616.591, 0], ix: 2 },
                  a: { a: 0, k: [-177.619, 0, 0], ix: 1 },
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                        o: { x: [1, 0.333, 0.333], y: [-0.006, 0, 0] },
                        n: [
                          "0p667_1_1_-0p006",
                          "0p667_1_0p333_0",
                          "0p667_1_0p333_0",
                        ],
                        t: 0,
                        s: [0, 100, 100],
                        e: [100, 100, 100],
                      },
                      {
                        i: { x: [0.649, 0.649, 0.649], y: [1, 1, 1] },
                        o: { x: [0.311, 0.311, 0.311], y: [0, 0, 0] },
                        n: [
                          "0p649_1_0p311_0",
                          "0p649_1_0p311_0",
                          "0p649_1_0p311_0",
                        ],
                        t: 15,
                        s: [100, 100, 100],
                        e: [196, 100, 100],
                      },
                      {
                        i: { x: [0.673, 0.673, 0.673], y: [0.833, 1, 1] },
                        o: { x: [0.326, 0.326, 0.326], y: [0, 0, 0] },
                        n: [
                          "0p673_0p833_0p326_0",
                          "0p673_1_0p326_0",
                          "0p673_1_0p326_0",
                        ],
                        t: 40,
                        s: [196, 100, 100],
                        e: [95.997, 100, 100],
                      },
                      {
                        i: { x: [0.71, 0.71, 0.71], y: [0.946, 1, 1] },
                        o: { x: [0.364, 0.364, 0.364], y: [-0.158, 0, 0] },
                        n: [
                          "0p71_0p946_0p364_-0p158",
                          "0p71_1_0p364_0",
                          "0p71_1_0p364_0",
                        ],
                        t: 88,
                        s: [95.997, 100, 100],
                        e: [189.07, 100, 100],
                      },
                      {
                        i: { x: [0.768, 0.768, 0.768], y: [1, 1, 1] },
                        o: { x: [0.42, 0.42, 0.42], y: [-0.052, 0, 0] },
                        n: [
                          "0p768_1_0p42_-0p052",
                          "0p768_1_0p42_0",
                          "0p768_1_0p42_0",
                        ],
                        t: 126,
                        s: [189.07, 100, 100],
                        e: [100, 100, 100],
                      },
                      {
                        i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                        o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                        n: [
                          "0p833_1_0p167_0",
                          "0p833_1_0p167_0",
                          "0p833_1_0p167_0",
                        ],
                        t: 150,
                        s: [100, 100, 100],
                        e: [0, 100, 100],
                      },
                      { t: 165 },
                    ],
                    ix: 6,
                  },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ty: "rc",
                        d: 1,
                        s: { a: 0, k: [355.237, 39.825], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        r: { a: 0, k: 0, ix: 4 },
                        nm: "Rectangle Path 1",
                        mn: "ADBE Vector Shape - Rect",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [1, 0.61960798502, 0.725489974022, 1],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Rectangle",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 49,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [950.997, 494.689, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, -156.14],
                              [156.15, 0],
                              [0, 156.14],
                              [-165.96, 25.14],
                            ],
                            o: [
                              [0, 156.15],
                              [-156.15, 0],
                              [0, -156.15],
                              [165.95, -25.14],
                            ],
                            v: [
                              [511.349, -188.727],
                              [-6.371, 359.263],
                              [-511.351, -27.827],
                              [-121.351, -351.557],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: { a: 0, k: [0.13, 0.29, 0.7, 1], ix: 4 },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
            ],
            markers: [],
          },
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        width="100%"
      />
    </>
  );
};

export default Component;
