import React from "react";
import Column from "../Column";
import Editing from "../animation/Editing";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const styles = {};
const Home = () => {
  return (
    <>
      <center>
        <div style={{ width: "100%", maxWidth: "800px" }}>
          <Editing />
        </div>
      </center>
      <Typography
        color="primary"
        style={{ paddingBottom: "24px", textAlign: "center" }}
        variant="h2"
      >
        we build software that matters
      </Typography>
      <p />
      <Column>
        <b>Successful software projects are hard</b>
        <p />
        They require project + product management, engineering, training,
        integration, and cross-functional and cross-agency alignment.
        <p />
        Software projects are tough. They fail often. And when they do work,
        they're often extremely expensive and off-schedule. <p />
        We're here to change all of that.
        <p />
        <b>We favor "wet agile"</b>
        <p />
        Agile is great for well-oiled software companies.
        <p />
        But we're an agency. And you're probably not an SV unicorn running
        sprints and "moving fast and breaking things" (to use Zuck's infamous
        advice for Facebook). <p />
        And so let's dispense with the fancy terms. We'll take what's best from
        agile, for sure. But we'll do so with the understanding that "waterfall"
        is often a necessary and important process for our customers. If you're
        a bank, you can't really sprint something out without careful
        forethought and planning. We gotcha.
        <p />
        <b>We get things done</b>
        <p />
        At the same time, we prefer not to waste your time with drawn out
        "discovery". Do you want stickies on your windows? We're happy to
        oblige... but we're not huge fans.
        <p />
        Instead, we favor an aligned vision and working software over everything
        else. This means:
        <p />
        <ul>
          <li>
            A focus on process engineering: how will our technology smoothly
            replace existing systems?
          </li>
          <li>
            Sufficient planning materials to sell internally and align
            cross-functionally
          </li>
          <li>Brief, prioritized feature lists</li>
          <li>Brief technical plans</li>
          <li>Open source whenever possible</li>
          <li>Cloud-based, whenever possible</li>
          <li>Microservices over monolithic apps</li>
          <li>Off-the-shelf over custom built</li>
        </ul>
        <p />
        <b>We're an effective and friendly extension of your team</b>
      </Column>
    </>
  );
};

export default withStyles(styles, { withTheme: true })(Home);
