import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/SEO";
import Page from "../components/Page";
import { withStyles } from "@material-ui/core/styles";

import Intro from "../components/Home/Intro";
import WOC from "../components/Home/WOC";
import Skills from "../components/Home/Skills";
import Process from "../components/Home/Process";

const styles = {};
const Home = (props) => {
  const {
    data: {
      Services: { edges: services },
      Basic: {
        siteMetadata: { domain, defaultTitle, defaultDescription },
      },
    },
  } = props;
  return (
    <Page>
      <SEO title={defaultTitle}>
        <meta content={defaultDescription} name="description" />
        <link href={domain} rel="canonical" />
      </SEO>
      <Intro />
      <WOC />
      <Skills services={services} />
      <Process />
    </Page>
  );
};

export const query = graphql`
  query Name {
    Basic: site {
      siteMetadata {
        domain
        defaultTitle
        defaultDescription
      }
    }
    Services: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/data/" } }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            imageLink
          }
        }
      }
    }
  }
`;

export default withStyles(styles, { withTheme: true })(Home);
