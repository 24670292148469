import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

const Component = ({ children }) => {
  return (
    <Grid alignItems="stretch" container justify="center" spacing={0}>
      <Grid item md={8} style={{ textAlign: "left" }} xs={12}>
        <Paper style={{ padding: "16px" }} variant="outlined">
          <Typography component="span" gutterBottom paragraph variant="body1">
            {children}
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Component;
