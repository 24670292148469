import React from "react";
import Lottie from "react-lottie-wrapper";

const Component = () => {
  return (
    <>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: {
            v: "5.2.1",
            fr: 30,
            ip: 0,
            op: 180,
            w: 1920,
            h: 1080,
            nm: "tasks black",
            ddd: 0,
            assets: [
              {
                id: "comp_0",
                layers: [
                  {
                    ddd: 0,
                    ind: 1,
                    ty: 4,
                    nm: "Path 2",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [48.411, 54.727, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                              a: 0,
                              k: {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                  [-2.86, 11.43],
                                  [-7.64, 7.33],
                                  [0, 0],
                                  [-1.91, -19.62],
                                  [7.5, -6.3],
                                  [0, 0],
                                  [9.57, -9.2],
                                ],
                                o: [
                                  [0, 0],
                                  [-5.66, 0.74],
                                  [0, 0],
                                  [0, 0],
                                  [2.86, -11.44],
                                  [8.71, -8.37],
                                  [0, 0],
                                  [0.95, 9.75],
                                  [-9.63, 8.09],
                                  [0, 0],
                                  [-6.76, 6.5],
                                ],
                                v: [
                                  [1.095, 43.654],
                                  [-9.415, 51.394],
                                  [-20.035, 51.394],
                                  [-27.655, 47.774],
                                  [-44.565, -6.546],
                                  [-33.125, -30.566],
                                  [-5.625, -39.936],
                                  [44.695, -43.936],
                                  [34.405, -22.776],
                                  [2.385, -17.626],
                                  [2.385, 18.274],
                                ],
                                c: true,
                              },
                              ix: 2,
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.105609998516,
                                0.105609998516,
                                0.105609998516,
                                1,
                              ],
                              ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false,
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                          },
                        ],
                        nm: "Path",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                      },
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0,
                  },
                  {
                    ddd: 0,
                    ind: 2,
                    ty: 4,
                    nm: "head 2",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [127.052, 412.439, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                              a: 0,
                              k: {
                                i: [
                                  [-0.39, 9.75],
                                  [3.26, 14.96],
                                  [8.01, -0.26],
                                  [1.39, 0],
                                  [-11.95, -40.39],
                                  [-3.42, -2.86],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [-2.1, -9.66],
                                  [-1.34, 0.04],
                                  [-27.31, 0],
                                  [1.71, 5.76],
                                  [0, 0],
                                  [0, 0],
                                  [9.53, 0],
                                ],
                                v: [
                                  [-49.476, -335.132],
                                  [-53.226, -374.792],
                                  [-70.006, -383.982],
                                  [-74.106, -383.892],
                                  [-113.116, -325.382],
                                  [-104.966, -312.702],
                                  [-69.706, -305.872],
                                  [-66.516, -305.872],
                                ],
                                c: true,
                              },
                              ix: 2,
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                          },
                          {
                            ty: "mm",
                            mm: 1,
                            nm: "Merge Paths 1",
                            mn: "ADBE Vector Filter - Merge",
                            hd: false,
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.639216005802,
                                0.415686011314,
                                0.415686011314,
                                1,
                              ],
                              ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false,
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                          },
                        ],
                        nm: "Shape",
                        np: 3,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                      },
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0,
                  },
                ],
              },
              {
                id: "comp_1",
                layers: [
                  {
                    ddd: 0,
                    ind: 1,
                    ty: 4,
                    nm: "Path 3",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [20.175, 38.31, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                              a: 0,
                              k: {
                                i: [
                                  [-30.61, -14.47],
                                  [13.17, 7.98],
                                ],
                                o: [
                                  [-7.57, -20.87],
                                  [0.31, 18.17],
                                ],
                                v: [
                                  [19.479, 36.409],
                                  [-19.481, -36.411],
                                ],
                                c: true,
                              },
                              ix: 2,
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.050980003207,
                                0.050980003207,
                                0.050980003207,
                                1,
                              ],
                              ix: 4,
                            },
                            o: { a: 0, k: 12, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false,
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                          },
                        ],
                        nm: "Path",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                      },
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0,
                  },
                  {
                    ddd: 0,
                    ind: 2,
                    ty: 4,
                    nm: "Path 2",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [20.494, 38.194, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                              a: 0,
                              k: {
                                i: [
                                  [-30.8, -15.21],
                                  [37.48, 13.73],
                                ],
                                o: [
                                  [2.63, -18.63],
                                  [0.15, 18.01],
                                ],
                                v: [
                                  [19.16, 36.523],
                                  [-19.8, -36.527],
                                ],
                                c: true,
                              },
                              ix: 2,
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.227450996637,
                                0.568626999855,
                                0.760783970356,
                                1,
                              ],
                              ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false,
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                          },
                        ],
                        nm: "Path",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                      },
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0,
                  },
                ],
              },
              {
                id: "comp_2",
                layers: [
                  {
                    ddd: 0,
                    ind: 1,
                    ty: 4,
                    nm: "Path 3",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [27.734, 51.489, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                              a: 0,
                              k: {
                                i: [
                                  [40.84, -19.3],
                                  [-17.58, 10.64],
                                ],
                                o: [
                                  [10.11, -27.84],
                                  [-0.42, 24.24],
                                ],
                                v: [
                                  [-25.988, 48.568],
                                  [25.992, -48.572],
                                ],
                                c: true,
                              },
                              ix: 2,
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.050980001688,
                                0.050980001688,
                                0.050980001688,
                                0.119999997318,
                              ],
                              ix: 4,
                            },
                            o: { a: 0, k: 12, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false,
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                          },
                        ],
                        nm: "Path",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                      },
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0,
                  },
                  {
                    ddd: 0,
                    ind: 2,
                    ty: 4,
                    nm: "Path 2",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [27.31, 51.334, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                              a: 0,
                              k: {
                                i: [
                                  [41.09, -20.29],
                                  [-50, 18.32],
                                ],
                                o: [
                                  [-3.51, -24.85],
                                  [-0.21, 24.03],
                                ],
                                v: [
                                  [-25.563, 48.723],
                                  [26.417, -48.727],
                                ],
                                c: true,
                              },
                              ix: 2,
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.211765006185,
                                0.458824008703,
                                0.639216005802,
                                1,
                              ],
                              ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false,
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                          },
                        ],
                        nm: "Path",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                      },
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0,
                  },
                ],
              },
              {
                id: "comp_3",
                layers: [
                  {
                    ddd: 0,
                    ind: 1,
                    ty: 4,
                    nm: "Path 3",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [30.511, 67.09, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                              a: 0,
                              k: {
                                i: [
                                  [30.42, -43.93],
                                  [-11.71, 21.3],
                                ],
                                o: [
                                  [-6.11, -34.5],
                                  [13.91, 25.07],
                                ],
                                v: [
                                  [-3.014, 65.129],
                                  [-7.224, -65.131],
                                ],
                                c: true,
                              },
                              ix: 2,
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.050980001688,
                                0.050980001688,
                                0.050980001688,
                                0.119999997318,
                              ],
                              ix: 4,
                            },
                            o: { a: 0, k: 12, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false,
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                          },
                        ],
                        nm: "Path",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                      },
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0,
                  },
                  {
                    ddd: 0,
                    ind: 2,
                    ty: 4,
                    nm: "Path 2",
                    sr: 1,
                    ks: {
                      o: { a: 0, k: 100, ix: 11 },
                      r: { a: 0, k: 0, ix: 10 },
                      p: { a: 0, k: [22.469, 66.932, 0], ix: 2 },
                      a: { a: 0, k: [0, 0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                      {
                        ty: "gr",
                        it: [
                          {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                              a: 0,
                              k: {
                                i: [
                                  [30.08, -45.09],
                                  [-40.38, 48.33],
                                ],
                                o: [
                                  [-18.29, -23.39],
                                  [14, 24.74],
                                ],
                                v: [
                                  [5.035, 65.291],
                                  [0.635, -65.289],
                                ],
                                c: true,
                              },
                              ix: 2,
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                          },
                          {
                            ty: "fl",
                            c: {
                              a: 0,
                              k: [
                                0.274509996176,
                                0.44313699007,
                                0.643136978149,
                                1,
                              ],
                              ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false,
                          },
                          {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                          },
                        ],
                        nm: "Path",
                        np: 2,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                      },
                    ],
                    ip: 0,
                    op: 180,
                    st: 0,
                    bm: 0,
                  },
                ],
              },
            ],
            layers: [
              {
                ddd: 0,
                ind: 1,
                ty: 4,
                nm: "arm",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        n: ["0p833_0p833_0p167_0p167"],
                        t: 31,
                        s: [0],
                        e: [17],
                      },
                      {
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        n: ["0p833_0p833_0p167_0p167"],
                        t: 40,
                        s: [17],
                        e: [17],
                      },
                      {
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        n: ["0p833_0p833_0p167_0p167"],
                        t: 144,
                        s: [17],
                        e: [0],
                      },
                      { t: 153 },
                    ],
                    ix: 10,
                  },
                  p: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        n: "0p833_0p833_0p167_0p167",
                        t: 31,
                        s: [973.941, 430.029, 0],
                        e: [953.941, 436.029, 0],
                        to: [-3.33333325386047, 1, 0],
                        ti: [3.33333325386047, -1, 0],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        n: "0p833_0p833_0p167_0p167",
                        t: 40,
                        s: [953.941, 436.029, 0],
                        e: [953.941, 436.029, 0],
                        to: [0, 0, 0],
                        ti: [0, 0, 0],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        n: "0p833_0p833_0p167_0p167",
                        t: 144,
                        s: [953.941, 436.029, 0],
                        e: [973.941, 430.029, 0],
                        to: [3.33333325386047, -1, 0],
                        ti: [-3.33333325386047, 1, 0],
                      },
                      { t: 153 },
                    ],
                    ix: 2,
                  },
                  a: { a: 0, k: [59, -165, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [-0.41, -11.25],
                              [15.62, 10.84],
                            ],
                            o: [
                              [0, 0],
                              [15.63, 0],
                              [-8.5, 23.5],
                              [-28.27, -19.62],
                            ],
                            v: [
                              [68.178, -204.65],
                              [118.688, -252.78],
                              [143.688, -228.4],
                              [52.558, -149.65],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "mm",
                        mm: 1,
                        nm: "Merge Paths 1",
                        mn: "ADBE Vector Filter - Merge",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [1, 0.411765009165, 0.411765009165, 1],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Shape",
                    np: 3,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 2,
                ty: 4,
                nm: "fa",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        n: ["0p833_0p833_0p167_0p167"],
                        t: 31,
                        s: [0],
                        e: [8],
                      },
                      {
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        n: ["0p833_0p833_0p167_0p167"],
                        t: 40,
                        s: [8],
                        e: [8],
                      },
                      {
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        n: ["0p833_0p833_0p167_0p167"],
                        t: 144,
                        s: [8],
                        e: [0],
                      },
                      { t: 153 },
                    ],
                    ix: 10,
                  },
                  p: { a: 0, k: [911.941, 290.029, 0], ix: 2 },
                  a: { a: 0, k: [-3, -305, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [-1.04, -2.35],
                              [0, 0],
                              [15.62, 10.84],
                              [6.93, 35.13],
                              [0, 0],
                              [0.56, 2.77],
                              [4.324, 34.166],
                            ],
                            o: [
                              [2.3, 1.15],
                              [16.43, 37.25],
                              [0, 0],
                              [-28.27, -19.62],
                              [0, 0],
                              [-0.58, -2.92],
                              [0, 0],
                              [-4.324, -34.166],
                            ],
                            v: [
                              [20.138, -323.93],
                              [25.318, -318.53],
                              [68.178, -204.65],
                              [52.558, -149.65],
                              [8.518, -249.23],
                              [8.518, -249.23],
                              [6.818, -257.78],
                              [-17.117, -305.363],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "mm",
                        mm: 1,
                        nm: "Merge Paths 1",
                        mn: "ADBE Vector Filter - Merge",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [1, 0.411765009165, 0.411765009165, 1],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Shape",
                    np: 3,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 3,
                ty: 4,
                nm: "hand",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        n: ["0p833_0p833_0p167_0p167"],
                        t: 31,
                        s: [0],
                        e: [28],
                      },
                      {
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        n: ["0p833_0p833_0p167_0p167"],
                        t: 40,
                        s: [28],
                        e: [19],
                      },
                      {
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        n: ["0p833_0p833_0p167_0p167"],
                        t: 43,
                        s: [19],
                        e: [28],
                      },
                      {
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        n: ["0p833_0p833_0p167_0p167"],
                        t: 46,
                        s: [28],
                        e: [28],
                      },
                      {
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        n: ["0p833_0p833_0p167_0p167"],
                        t: 129,
                        s: [28],
                        e: [19],
                      },
                      {
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        n: ["0p833_0p833_0p167_0p167"],
                        t: 132,
                        s: [19],
                        e: [28],
                      },
                      {
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        n: ["0p833_0p833_0p167_0p167"],
                        t: 135,
                        s: [28],
                        e: [28],
                      },
                      {
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        n: ["0p833_0p833_0p167_0p167"],
                        t: 144,
                        s: [28],
                        e: [0],
                      },
                      { t: 153 },
                    ],
                    ix: 10,
                  },
                  p: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        n: "0p833_0p833_0p167_0p167",
                        t: 31,
                        s: [1044.052, 354.439, 0],
                        e: [1047.052, 388.439, 0],
                        to: [0.5, 5.66666650772095, 0],
                        ti: [-0.5, -5.66666650772095, 0],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        n: "0p833_0p833_0p167_0p167",
                        t: 40,
                        s: [1047.052, 388.439, 0],
                        e: [1047.052, 388.439, 0],
                        to: [0, 0, 0],
                        ti: [0, 0, 0],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        n: "0p833_0p833_0p167_0p167",
                        t: 144,
                        s: [1047.052, 388.439, 0],
                        e: [1044.052, 354.439, 0],
                        to: [-0.5, -5.66666650772095, 0],
                        ti: [0.5, 5.66666650772095, 0],
                      },
                      { t: 153 },
                    ],
                    ix: 2,
                  },
                  a: { a: 0, k: [91, -185, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, -8.79],
                              [0, 0],
                              [0, 0],
                              [7.5, -4.38],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 13.12],
                              [0, 0],
                              [1.25, 8.75],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [87.454, -197.189],
                              [112.454, -237.809],
                              [107.454, -215.939],
                              [121.204, -204.059],
                              [103.084, -182.809],
                              [91.204, -169.689],
                              [74.954, -175.939],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 2",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "mm",
                        mm: 1,
                        nm: "Merge Paths 1",
                        mn: "ADBE Vector Filter - Merge",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.639216005802,
                            0.415686011314,
                            0.415686011314,
                            1,
                          ],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Shape",
                    np: 3,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 4,
                ty: 4,
                nm: "check 7",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1037.438, 364.812, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-11.09, 2.66],
                              [-2.22, 9.76],
                              [11.09, -9.76],
                            ],
                            c: false,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "st",
                        c: {
                          a: 0,
                          k: [
                            0.160784006119,
                            0.478430986404,
                            0.439215987921,
                            1,
                          ],
                          ix: 3,
                        },
                        o: { a: 0, k: 100, ix: 4 },
                        w: { a: 0, k: 5.323, ix: 5 },
                        lc: 1,
                        lj: 1,
                        ml: 4,
                        ml2: { a: 0, k: 4, ix: 8 },
                        nm: "Stroke 1",
                        mn: "ADBE Vector Graphic - Stroke",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                  {
                    ty: "tm",
                    s: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.667], y: [1] },
                          o: { x: [1], y: [-0.002] },
                          n: ["0p667_1_1_-0p002"],
                          t: 135,
                          s: [0],
                          e: [100],
                        },
                        { t: 145 },
                      ],
                      ix: 1,
                    },
                    e: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.667], y: [1] },
                          o: { x: [1], y: [-0.001] },
                          n: ["0p667_1_1_-0p001"],
                          t: 43,
                          s: [0],
                          e: [100],
                        },
                        { t: 53 },
                      ],
                      ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: "Trim Paths 1",
                    mn: "ADBE Vector Filter - Trim",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 5,
                ty: 4,
                nm: "check 6",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1037.438, 302.812, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-11.09, 2.66],
                              [-2.22, 9.76],
                              [11.09, -9.76],
                            ],
                            c: false,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "st",
                        c: {
                          a: 0,
                          k: [
                            0.160784006119,
                            0.478430986404,
                            0.439215987921,
                            1,
                          ],
                          ix: 3,
                        },
                        o: { a: 0, k: 100, ix: 4 },
                        w: { a: 0, k: 5.323, ix: 5 },
                        lc: 1,
                        lj: 1,
                        ml: 4,
                        ml2: { a: 0, k: 4, ix: 8 },
                        nm: "Stroke 1",
                        mn: "ADBE Vector Graphic - Stroke",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                  {
                    ty: "tm",
                    s: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.667], y: [1] },
                          o: { x: [1], y: [-0.002] },
                          n: ["0p667_1_1_-0p002"],
                          t: 141,
                          s: [0],
                          e: [100],
                        },
                        { t: 151 },
                      ],
                      ix: 1,
                    },
                    e: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.667], y: [1] },
                          o: { x: [1], y: [-0.001] },
                          n: ["0p667_1_1_-0p001"],
                          t: 15,
                          s: [0],
                          e: [100],
                        },
                        { t: 25 },
                      ],
                      ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: "Trim Paths 1",
                    mn: "ADBE Vector Filter - Trim",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 6,
                ty: 4,
                nm: "check 5",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1037.438, 239.062, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-11.09, 2.66],
                              [-2.22, 9.76],
                              [11.09, -9.76],
                            ],
                            c: false,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "st",
                        c: {
                          a: 0,
                          k: [
                            0.160784006119,
                            0.478430986404,
                            0.439215987921,
                            1,
                          ],
                          ix: 3,
                        },
                        o: { a: 0, k: 100, ix: 4 },
                        w: { a: 0, k: 5.323, ix: 5 },
                        lc: 1,
                        lj: 1,
                        ml: 4,
                        ml2: { a: 0, k: 4, ix: 8 },
                        nm: "Stroke 1",
                        mn: "ADBE Vector Graphic - Stroke",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                  {
                    ty: "tm",
                    s: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.667], y: [1] },
                          o: { x: [1], y: [-0.002] },
                          n: ["0p667_1_1_-0p002"],
                          t: 144,
                          s: [0],
                          e: [100],
                        },
                        { t: 154 },
                      ],
                      ix: 1,
                    },
                    e: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.667], y: [1] },
                          o: { x: [1], y: [-0.001] },
                          n: ["0p667_1_1_-0p001"],
                          t: 12,
                          s: [0],
                          e: [100],
                        },
                        { t: 22 },
                      ],
                      ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: "Trim Paths 1",
                    mn: "ADBE Vector Filter - Trim",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 7,
                ty: 4,
                nm: "Rectangle",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1036.175, 430.558, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ty: "rc",
                        d: 1,
                        s: { a: 0, k: [33.714, 33.714], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        r: { a: 0, k: 5.323, ix: 4 },
                        nm: "Rectangle Path 1",
                        mn: "ADBE Vector Shape - Rect",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Rectangle",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 8,
                ty: 4,
                nm: "Rectangle",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1128.445, 439.43, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ty: "rc",
                        d: 1,
                        s: { a: 0, k: [118.887, 8.872], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        r: { a: 0, k: 4.436, ix: 4 },
                        nm: "Rectangle Path 1",
                        mn: "ADBE Vector Shape - Rect",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Rectangle",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 9,
                ty: 4,
                nm: "Rectangle",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1177.242, 421.686, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ty: "rc",
                        d: 1,
                        s: { a: 0, k: [216.48, 8.872], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        r: { a: 0, k: 4.436, ix: 4 },
                        nm: "Rectangle Path 1",
                        mn: "ADBE Vector Shape - Rect",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Rectangle",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 10,
                ty: 4,
                nm: "Rectangle",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1036.175, 366.678, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ty: "rc",
                        d: 1,
                        s: { a: 0, k: [33.714, 33.714], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        r: { a: 0, k: 5.323, ix: 4 },
                        nm: "Rectangle Path 1",
                        mn: "ADBE Vector Shape - Rect",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Rectangle",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 11,
                ty: 4,
                nm: "Rectangle",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1128.445, 375.549, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ty: "rc",
                        d: 1,
                        s: { a: 0, k: [118.887, 8.872], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        r: { a: 0, k: 4.436, ix: 4 },
                        nm: "Rectangle Path 1",
                        mn: "ADBE Vector Shape - Rect",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Rectangle",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 12,
                ty: 4,
                nm: "Rectangle",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1177.242, 357.806, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ty: "rc",
                        d: 1,
                        s: { a: 0, k: [216.48, 8.872], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        r: { a: 0, k: 4.436, ix: 4 },
                        nm: "Rectangle Path 1",
                        mn: "ADBE Vector Shape - Rect",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Rectangle",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 13,
                ty: 4,
                nm: "Rectangle",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1036.175, 302.799, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ty: "rc",
                        d: 1,
                        s: { a: 0, k: [33.714, 33.714], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        r: { a: 0, k: 5.323, ix: 4 },
                        nm: "Rectangle Path 1",
                        mn: "ADBE Vector Shape - Rect",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Rectangle",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 14,
                ty: 4,
                nm: "Rectangle",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1128.445, 311.67, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ty: "rc",
                        d: 1,
                        s: { a: 0, k: [118.887, 8.872], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        r: { a: 0, k: 4.436, ix: 4 },
                        nm: "Rectangle Path 1",
                        mn: "ADBE Vector Shape - Rect",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Rectangle",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 15,
                ty: 4,
                nm: "Rectangle",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1177.242, 293.927, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ty: "rc",
                        d: 1,
                        s: { a: 0, k: [216.48, 8.872], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        r: { a: 0, k: 4.436, ix: 4 },
                        nm: "Rectangle Path 1",
                        mn: "ADBE Vector Shape - Rect",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Rectangle",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 16,
                ty: 4,
                nm: "Rectangle",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1036.175, 238.92, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ty: "rc",
                        d: 1,
                        s: { a: 0, k: [33.714, 33.714], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        r: { a: 0, k: 5.323, ix: 4 },
                        nm: "Rectangle Path 1",
                        mn: "ADBE Vector Shape - Rect",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Rectangle",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 17,
                ty: 4,
                nm: "Rectangle",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1128.445, 247.791, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ty: "rc",
                        d: 1,
                        s: { a: 0, k: [118.887, 8.872], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        r: { a: 0, k: 4.436, ix: 4 },
                        nm: "Rectangle Path 1",
                        mn: "ADBE Vector Shape - Rect",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Rectangle",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 18,
                ty: 4,
                nm: "Rectangle",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1177.242, 230.048, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ty: "rc",
                        d: 1,
                        s: { a: 0, k: [216.48, 8.872], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        r: { a: 0, k: 4.436, ix: 4 },
                        nm: "Rectangle Path 1",
                        mn: "ADBE Vector Shape - Rect",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Rectangle",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 19,
                ty: 4,
                nm: "Rectangle",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1150.624, 333.851, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ty: "rc",
                        d: 1,
                        s: { a: 0, k: [308.75, 305.201], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        r: { a: 0, k: 17.744, ix: 4 },
                        nm: "Rectangle Path 1",
                        mn: "ADBE Vector Shape - Rect",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.9882352941176471,
                            0.6666666666666666,
                            0.8117647058823529,
                            1,
                          ],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Rectangle",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 20,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [752.812, 928.438, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-27.188, 19.693],
                              [-13.438, -19.688],
                              [27.193, -19.688],
                              [9.062, 19.693],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.101960784314,
                            0.101960784314,
                            0.101960784314,
                            1,
                          ],
                          ix: 4,
                        },
                        o: { a: 0, k: 7, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 21,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [705, 928.438, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [34.38, -19.688],
                              [-75, -19.688],
                              [-55, 19.693],
                              [56.88, 19.693],
                              [75, -19.688],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [0.780391991138, 0.882353007793, 1, 1],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 22,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [909.546, 737.829, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [6.25, 58.75],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-2.92, 2.6],
                            ],
                            o: [
                              [-4.57, -42.88],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [16.88, -15],
                            ],
                            v: [
                              [5.966, -18.702],
                              [-37.164, -148.702],
                              [-7.164, -33.702],
                              [22.836, 147.548],
                              [32.836, 147.548],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.101960784314,
                            0.101960784314,
                            0.101960784314,
                            1,
                          ],
                          ix: 4,
                        },
                        o: { a: 0, k: 21, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 23,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [874.376, 930, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-14.38, -4.37],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [13.74, 3.75],
                              [0, 0],
                            ],
                            v: [
                              [12.505, 7.5],
                              [-18.125, 0.63],
                              [-18.125, -7.5],
                              [-13.745, -5.62],
                              [18.125, 3.12],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.101960784314,
                            0.101960784314,
                            0.101960784314,
                            1,
                          ],
                          ix: 4,
                        },
                        o: { a: 0, k: 22, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 24,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [864.883, 945.036, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [-1.5, 8.5],
                            ],
                            o: [
                              [22.08, 1.66],
                              [0, 0],
                            ],
                            v: [
                              [-34.375, 2.221],
                              [34.375, -3.41],
                            ],
                            c: false,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "st",
                        c: {
                          a: 0,
                          k: [
                            0.101960784314,
                            0.101960784314,
                            0.101960784314,
                            1,
                          ],
                          ix: 3,
                        },
                        o: { a: 0, k: 100, ix: 4 },
                        w: { a: 0, k: 1.25, ix: 5 },
                        lc: 1,
                        lj: 1,
                        ml: 4,
                        ml2: { a: 0, k: 4, ix: 8 },
                        nm: "Stroke 1",
                        mn: "ADBE Vector Graphic - Stroke",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 25,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [986.873, 930.312, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-14.38, -3.75],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [11.25, 3.75],
                              [0, 0],
                            ],
                            v: [
                              [12.505, 7.183],
                              [-18.125, 0.313],
                              [-18.125, -7.187],
                              [-11.255, -4.067],
                              [18.125, 4.063],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.101960784314,
                            0.101960784314,
                            0.101960784314,
                            1,
                          ],
                          ix: 4,
                        },
                        o: { a: 0, k: 22, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 26,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [976.756, 945.037, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [-1.5, 8.5],
                            ],
                            o: [
                              [22.08, 1.66],
                              [0, 0],
                            ],
                            v: [
                              [-34.375, 2.221],
                              [34.375, -3.41],
                            ],
                            c: false,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "st",
                        c: {
                          a: 0,
                          k: [
                            0.101960784314,
                            0.101960784314,
                            0.101960784314,
                            1,
                          ],
                          ix: 3,
                        },
                        o: { a: 0, k: 100, ix: 4 },
                        w: { a: 0, k: 1.25, ix: 5 },
                        lc: 1,
                        lj: 1,
                        ml: 4,
                        ml2: { a: 0, k: 4, ix: 8 },
                        nm: "Stroke 1",
                        mn: "ADBE Vector Graphic - Stroke",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 27,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [801.026, 389.441, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0.49, 22.23],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [-0.48, -21.96],
                              [0, 0],
                            ],
                            v: [
                              [1.353, 56.567],
                              [-1.147, -0.313],
                              [-1.147, -56.563],
                            ],
                            c: false,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "st",
                        c: {
                          a: 0,
                          k: [
                            0.101960784314,
                            0.101960784314,
                            0.101960784314,
                            1,
                          ],
                          ix: 3,
                        },
                        o: { a: 0, k: 29, ix: 4 },
                        w: { a: 0, k: 1.25, ix: 5 },
                        lc: 2,
                        lj: 1,
                        ml: 4,
                        ml2: { a: 0, k: 4, ix: 8 },
                        nm: "Stroke 1",
                        mn: "ADBE Vector Graphic - Stroke",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 28,
                ty: 4,
                nm: "Shape",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [914.941, 595.029, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [-2.02, 1.38],
                              [0, 0],
                              [-11.03, -2.43],
                              [-3.26, -0.52],
                              [0, 0],
                              [0.56, 2.77],
                              [-4.78, -31.86],
                              [0, 0],
                              [24.38, 12.12],
                              [0, 0],
                              [-8.6, 33.03],
                              [-1.16, 6.41],
                            ],
                            o: [
                              [0, 0],
                              [19.28, -6.43],
                              [2.91, 0.64],
                              [0, 0],
                              [2.3, 1.15],
                              [0, 0],
                              [3.64, 24.24],
                              [-11.87, 32.75],
                              [0, 0],
                              [-41.65, -68.24],
                              [1.76, -6.78],
                              [0.43, -2.41],
                            ],
                            v: [
                              [-127.512, -316.19],
                              [-79.432, -349.03],
                              [-39.222, -350.85],
                              [-30.062, -349.03],
                              [20.138, -323.93],
                              [6.818, -257.78],
                              [18.688, -160.9],
                              [18.688, -109.03],
                              [-103.812, -92.15],
                              [-113.182, -149.65],
                              [-135.912, -290.56],
                              [-131.302, -310.24],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ind: 1,
                        ty: "sh",
                        ix: 2,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0.24, 0.59],
                              [-5.4, 13.03],
                              [-4.43, -0.05],
                              [0, 0],
                              [-9.15, -2.19],
                              [-2.17, -1.01],
                              [9.94, 2.12],
                            ],
                            o: [
                              [-0.27, -0.66],
                              [-4.36, -10.67],
                              [0, 0],
                              [4.71, 0.04],
                              [9.81, 5.42],
                              [4.98, 1.2],
                              [17.57, 8.24],
                              [0, 0],
                            ],
                            v: [
                              [27.51, 352.849],
                              [26.75, 350.979],
                              [27.51, 326.599],
                              [38.69, 328.479],
                              [50.59, 326.599],
                              [81.89, 337.169],
                              [93.07, 340.349],
                              [65.74, 352.849],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 2",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ind: 2,
                        ty: "sh",
                        ix: 3,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [-4.27, -10.43],
                              [-0.3, -0.75],
                              [0, 0],
                              [-0.84, -0.01],
                              [12.76, 12.8],
                              [8.51, 2.22],
                              [7.4, 3.17],
                              [4.71, 0.05],
                              [0, 0],
                            ],
                            o: [
                              [0.27, 0.67],
                              [0, 0],
                              [0.66, 0],
                              [11.45, 0.09],
                              [-1.94, -1.94],
                              [-8.12, -2.12],
                              [0, 0],
                              [-4.43, -0.04],
                              [-5.45, 12.93],
                            ],
                            v: [
                              [-85.004, 350.729],
                              [-84.144, 352.849],
                              [-45.914, 352.849],
                              [-43.644, 352.859],
                              [-18.584, 340.349],
                              [-36.144, 334.339],
                              [-61.064, 326.599],
                              [-72.964, 328.469],
                              [-84.144, 326.599],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 3",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "mm",
                        mm: 1,
                        nm: "Merge Paths 1",
                        mn: "ADBE Vector Filter - Merge",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [1, 0.411765009165, 0.411765009165, 1],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Shape",
                    np: 5,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 29,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [829.103, 701.94, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [3.12, 110.63],
                              [0, 0],
                              [-8.75, -161.88],
                              [1.79, 11.78],
                            ],
                            o: [
                              [-0.92, -32.41],
                              [0, 41.25],
                              [0, 0],
                              [-18.13, -118.75],
                            ],
                            v: [
                              [17.658, -118.438],
                              [-17.972, -193.438],
                              [-5.472, 193.442],
                              [17.658, 172.812],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.101960784314,
                            0.101960784314,
                            0.101960784314,
                            1,
                          ],
                          ix: 4,
                        },
                        o: { a: 0, k: 12, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 30,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [892.036, 695.103, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [-21.87, 0.89],
                              [0, 0],
                              [0, 0],
                              [-7.5, -81.63],
                              [6.87, 8.12],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [15.24, 9.22],
                            ],
                            o: [
                              [-8.13, -138.75],
                              [0, 0],
                              [31.5, 1.52],
                              [0, 0],
                              [0, 0],
                              [-5.63, 7.5],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-16.5, 9.32],
                              [0, 0],
                            ],
                            v: [
                              [-74.649, 6.529],
                              [-80.899, -193.471],
                              [-12.149, -188.471],
                              [42.221, -212.851],
                              [65.351, 6.529],
                              [81.091, 205.899],
                              [42.851, 205.899],
                              [9.721, 6.529],
                              [-19.029, -103.471],
                              [-19.029, 6.529],
                              [-29.029, 205.899],
                              [-68.399, 205.899],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.247059002519,
                            0.678430974483,
                            0.811765015125,
                            1,
                          ],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 31,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [837.07, 911.627, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-2.188, 17.5],
                              [-4.688, -17.5],
                              [3.443, -17.5],
                              [4.693, 17.5],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.101960784314,
                            0.101960784314,
                            0.101960784314,
                            1,
                          ],
                          ix: 4,
                        },
                        o: { a: 0, k: 22, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 32,
                ty: 0,
                nm: "head",
                refId: "comp_0",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 22,
                        s: [0],
                        e: [16],
                      },
                      {
                        i: { x: [0.833], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p833_1_0p333_0"],
                        t: 32,
                        s: [16],
                        e: [16],
                      },
                      {
                        i: { x: [0.833], y: [1] },
                        o: { x: [0.167], y: [0] },
                        n: ["0p833_1_0p167_0"],
                        t: 147,
                        s: [16],
                        e: [0],
                      },
                      { t: 157 },
                    ],
                    ix: 10,
                  },
                  p: { a: 0, k: [868.5, 223, 0], ix: 2 },
                  a: { a: 0, k: [42.5, 96, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                w: 97,
                h: 111,
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 33,
                ty: 4,
                nm: "Path",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [947.383, 906.627, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-2.495, 17.5],
                              [-4.375, -17.5],
                              [3.125, -17.5],
                              [4.375, 17.5],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.101960784314,
                            0.101960784314,
                            0.101960784314,
                            1,
                          ],
                          ix: 4,
                        },
                        o: { a: 0, k: 22, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 34,
                ty: 4,
                nm: "Shape",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [953.052, 539.439, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [-1.902, -1.591],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [12.049, 1.785],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-12.049, -1.785],
                            ],
                            v: [
                              [-104.966, -312.702],
                              [-100.486, -283.052],
                              [-100.486, -270.032],
                              [-67.076, -270.032],
                              [-67.076, -281.352],
                              [-69.706, -305.872],
                              [-84.503, -326.654],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ind: 1,
                        ty: "sh",
                        ix: 2,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-8.171, 384.058],
                              [-10.671, 349.688],
                              [9.959, 349.688],
                              [9.959, 384.058],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 3",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ind: 2,
                        ty: "sh",
                        ix: 3,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-121.296, 349.688],
                              [-118.796, 384.058],
                              [-100.666, 384.058],
                              [-100.666, 349.688],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "Path 4",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "mm",
                        mm: 1,
                        nm: "Merge Paths 1",
                        mn: "ADBE Vector Filter - Merge",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.639216005802,
                            0.415686011314,
                            0.415686011314,
                            1,
                          ],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Shape",
                    np: 5,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 35,
                ty: 4,
                nm: "check 4",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [654.813, 494.312, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-11.09, 2.66],
                              [-2.22, 9.76],
                              [11.09, -9.76],
                            ],
                            c: false,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "st",
                        c: {
                          a: 0,
                          k: [
                            0.160784006119,
                            0.478430986404,
                            0.439215987921,
                            1,
                          ],
                          ix: 3,
                        },
                        o: { a: 0, k: 100, ix: 4 },
                        w: { a: 0, k: 5.323, ix: 5 },
                        lc: 1,
                        lj: 1,
                        ml: 4,
                        ml2: { a: 0, k: 4, ix: 8 },
                        nm: "Stroke 1",
                        mn: "ADBE Vector Graphic - Stroke",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                  {
                    ty: "tm",
                    s: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.667], y: [1] },
                          o: { x: [1], y: [-0.002] },
                          n: ["0p667_1_1_-0p002"],
                          t: 147,
                          s: [0],
                          e: [100],
                        },
                        { t: 157 },
                      ],
                      ix: 1,
                    },
                    e: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.667], y: [1] },
                          o: { x: [1], y: [-0.001] },
                          n: ["0p667_1_1_-0p001"],
                          t: 9,
                          s: [0],
                          e: [100],
                        },
                        { t: 19 },
                      ],
                      ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: "Trim Paths 1",
                    mn: "ADBE Vector Filter - Trim",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 36,
                ty: 4,
                nm: "check 3",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [656.063, 432.312, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-11.09, 2.66],
                              [-2.22, 9.76],
                              [11.09, -9.76],
                            ],
                            c: false,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "st",
                        c: {
                          a: 0,
                          k: [
                            0.160784006119,
                            0.478430986404,
                            0.439215987921,
                            1,
                          ],
                          ix: 3,
                        },
                        o: { a: 0, k: 100, ix: 4 },
                        w: { a: 0, k: 5.323, ix: 5 },
                        lc: 1,
                        lj: 1,
                        ml: 4,
                        ml2: { a: 0, k: 4, ix: 8 },
                        nm: "Stroke 1",
                        mn: "ADBE Vector Graphic - Stroke",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                  {
                    ty: "tm",
                    s: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.667], y: [1] },
                          o: { x: [1], y: [-0.002] },
                          n: ["0p667_1_1_-0p002"],
                          t: 150,
                          s: [0],
                          e: [100],
                        },
                        { t: 160 },
                      ],
                      ix: 1,
                    },
                    e: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.667], y: [1] },
                          o: { x: [1], y: [-0.001] },
                          n: ["0p667_1_1_-0p001"],
                          t: 6,
                          s: [0],
                          e: [100],
                        },
                        { t: 16 },
                      ],
                      ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: "Trim Paths 1",
                    mn: "ADBE Vector Filter - Trim",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 37,
                ty: 4,
                nm: "check 2",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [657.563, 370.312, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-11.09, 2.66],
                              [-2.22, 9.76],
                              [11.09, -9.76],
                            ],
                            c: false,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "st",
                        c: {
                          a: 0,
                          k: [
                            0.160784006119,
                            0.478430986404,
                            0.439215987921,
                            1,
                          ],
                          ix: 3,
                        },
                        o: { a: 0, k: 100, ix: 4 },
                        w: { a: 0, k: 5.323, ix: 5 },
                        lc: 1,
                        lj: 1,
                        ml: 4,
                        ml2: { a: 0, k: 4, ix: 8 },
                        nm: "Stroke 1",
                        mn: "ADBE Vector Graphic - Stroke",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                  {
                    ty: "tm",
                    s: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.667], y: [1] },
                          o: { x: [1], y: [-0.002] },
                          n: ["0p667_1_1_-0p002"],
                          t: 153,
                          s: [0],
                          e: [100],
                        },
                        { t: 163 },
                      ],
                      ix: 1,
                    },
                    e: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.667], y: [1] },
                          o: { x: [1], y: [-0.001] },
                          n: ["0p667_1_1_-0p001"],
                          t: 3,
                          s: [0],
                          e: [100],
                        },
                        { t: 13 },
                      ],
                      ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: "Trim Paths 1",
                    mn: "ADBE Vector Filter - Trim",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 38,
                ty: 4,
                nm: "check",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [657.563, 306.562, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-11.09, 2.66],
                              [-2.22, 9.76],
                              [11.09, -9.76],
                            ],
                            c: false,
                          },
                          ix: 2,
                        },
                        nm: "Path 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "st",
                        c: {
                          a: 0,
                          k: [
                            0.160784006119,
                            0.478430986404,
                            0.439215987921,
                            1,
                          ],
                          ix: 3,
                        },
                        o: { a: 0, k: 100, ix: 4 },
                        w: { a: 0, k: 5.323, ix: 5 },
                        lc: 1,
                        lj: 1,
                        ml: 4,
                        ml2: { a: 0, k: 4, ix: 8 },
                        nm: "Stroke 1",
                        mn: "ADBE Vector Graphic - Stroke",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Path",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                  {
                    ty: "tm",
                    s: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.667], y: [1] },
                          o: { x: [1], y: [-0.002] },
                          n: ["0p667_1_1_-0p002"],
                          t: 156,
                          s: [0],
                          e: [100],
                        },
                        { t: 166 },
                      ],
                      ix: 1,
                    },
                    e: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.667], y: [1] },
                          o: { x: [1], y: [-0.001] },
                          n: ["0p667_1_1_-0p001"],
                          t: 0,
                          s: [0],
                          e: [100],
                        },
                        { t: 10 },
                      ],
                      ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: "Trim Paths 1",
                    mn: "ADBE Vector Filter - Trim",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 39,
                ty: 4,
                nm: "Rectangle",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [656.177, 498.058, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ty: "rc",
                        d: 1,
                        s: { a: 0, k: [33.714, 33.714], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        r: { a: 0, k: 5.323, ix: 4 },
                        nm: "Rectangle Path 1",
                        mn: "ADBE Vector Shape - Rect",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Rectangle",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 40,
                ty: 4,
                nm: "Rectangle",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [748.447, 506.93, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ty: "rc",
                        d: 1,
                        s: { a: 0, k: [118.887, 8.872], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        r: { a: 0, k: 4.436, ix: 4 },
                        nm: "Rectangle Path 1",
                        mn: "ADBE Vector Shape - Rect",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Rectangle",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 41,
                ty: 4,
                nm: "Rectangle",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [797.244, 489.186, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ty: "rc",
                        d: 1,
                        s: { a: 0, k: [216.48, 8.872], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        r: { a: 0, k: 4.436, ix: 4 },
                        nm: "Rectangle Path 1",
                        mn: "ADBE Vector Shape - Rect",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Rectangle",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 42,
                ty: 4,
                nm: "Rectangle",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [656.177, 434.178, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ty: "rc",
                        d: 1,
                        s: { a: 0, k: [33.714, 33.714], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        r: { a: 0, k: 5.323, ix: 4 },
                        nm: "Rectangle Path 1",
                        mn: "ADBE Vector Shape - Rect",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Rectangle",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 43,
                ty: 4,
                nm: "Rectangle",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [748.447, 443.049, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ty: "rc",
                        d: 1,
                        s: { a: 0, k: [118.887, 8.872], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        r: { a: 0, k: 4.436, ix: 4 },
                        nm: "Rectangle Path 1",
                        mn: "ADBE Vector Shape - Rect",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Rectangle",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 44,
                ty: 4,
                nm: "Rectangle",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [797.244, 425.306, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ty: "rc",
                        d: 1,
                        s: { a: 0, k: [216.48, 8.872], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        r: { a: 0, k: 4.436, ix: 4 },
                        nm: "Rectangle Path 1",
                        mn: "ADBE Vector Shape - Rect",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Rectangle",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 45,
                ty: 4,
                nm: "Rectangle",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [656.177, 370.299, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ty: "rc",
                        d: 1,
                        s: { a: 0, k: [33.714, 33.714], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        r: { a: 0, k: 5.323, ix: 4 },
                        nm: "Rectangle Path 1",
                        mn: "ADBE Vector Shape - Rect",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Rectangle",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 46,
                ty: 4,
                nm: "Rectangle",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [748.447, 379.17, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ty: "rc",
                        d: 1,
                        s: { a: 0, k: [118.887, 8.872], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        r: { a: 0, k: 4.436, ix: 4 },
                        nm: "Rectangle Path 1",
                        mn: "ADBE Vector Shape - Rect",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Rectangle",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 47,
                ty: 4,
                nm: "Rectangle",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [797.244, 361.427, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ty: "rc",
                        d: 1,
                        s: { a: 0, k: [216.48, 8.872], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        r: { a: 0, k: 4.436, ix: 4 },
                        nm: "Rectangle Path 1",
                        mn: "ADBE Vector Shape - Rect",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Rectangle",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 48,
                ty: 4,
                nm: "Rectangle",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [656.177, 306.42, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ty: "rc",
                        d: 1,
                        s: { a: 0, k: [33.714, 33.714], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        r: { a: 0, k: 5.323, ix: 4 },
                        nm: "Rectangle Path 1",
                        mn: "ADBE Vector Shape - Rect",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Rectangle",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 49,
                ty: 4,
                nm: "Rectangle",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [748.447, 315.291, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ty: "rc",
                        d: 1,
                        s: { a: 0, k: [118.887, 8.872], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        r: { a: 0, k: 4.436, ix: 4 },
                        nm: "Rectangle Path 1",
                        mn: "ADBE Vector Shape - Rect",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Rectangle",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 50,
                ty: 4,
                nm: "Rectangle",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [797.244, 297.548, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ty: "rc",
                        d: 1,
                        s: { a: 0, k: [216.48, 8.872], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        r: { a: 0, k: 4.436, ix: 4 },
                        nm: "Rectangle Path 1",
                        mn: "ADBE Vector Shape - Rect",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Rectangle",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 51,
                ty: 4,
                nm: "Rectangle",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [770.626, 401.351, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ty: "rc",
                        d: 1,
                        s: { a: 0, k: [308.75, 305.201], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        r: { a: 0, k: 17.744, ix: 4 },
                        nm: "Rectangle Path 1",
                        mn: "ADBE Vector Shape - Rect",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.13333333333333333,
                            0.28627450980392155,
                            0.7019607843137254,
                            1,
                          ],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Rectangle",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 52,
                ty: 0,
                nm: "l3",
                refId: "comp_1",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 0,
                        s: [0],
                        e: [5],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 39,
                        s: [5],
                        e: [-5],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 92,
                        s: [-5],
                        e: [5],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 142,
                        s: [5],
                        e: [0],
                      },
                      { t: 180 },
                    ],
                    ix: 10,
                  },
                  p: { a: 0, k: [691, 906.5, 0], ix: 2 },
                  a: { a: 0, k: [37, 65, 0], ix: 1 },
                  s: { a: 0, k: [200, 200, 100], ix: 6 },
                },
                ao: 0,
                w: 42,
                h: 77,
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 53,
                ty: 0,
                nm: "l2",
                refId: "comp_2",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 0,
                        s: [0],
                        e: [-5],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 46,
                        s: [-5],
                        e: [5],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 88,
                        s: [5],
                        e: [-5],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 133,
                        s: [-5],
                        e: [0],
                      },
                      { t: 180 },
                    ],
                    ix: 10,
                  },
                  p: { a: 0, k: [702.5, 906, 0], ix: 2 },
                  a: { a: 0, k: [5, 91, 0], ix: 1 },
                  s: { a: 0, k: [200, 200, 100], ix: 6 },
                },
                ao: 0,
                w: 55,
                h: 102,
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 54,
                ty: 0,
                nm: "l1",
                refId: "comp_3",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 0,
                        s: [0],
                        e: [5],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 41.333,
                        s: [5],
                        e: [-5],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 90.667,
                        s: [-5],
                        e: [5],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        n: ["0p667_1_0p333_0"],
                        t: 140,
                        s: [5],
                        e: [0],
                      },
                      { t: 180 },
                    ],
                    ix: 10,
                  },
                  p: { a: 0, k: [697.5, 886.5, 0], ix: 2 },
                  a: { a: 0, k: [24.5, 127.5, 0], ix: 1 },
                  s: { a: 0, k: [200, 200, 100], ix: 6 },
                },
                ao: 0,
                w: 45,
                h: 134,
                ip: 0,
                op: 180,
                st: 0,
                bm: 0,
              },
            ],
            markers: [],
          },

          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        width="100%"
      />
    </>
  );
};

export default Component;
