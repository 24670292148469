import React from "react";
import Column from "../Column";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Working from "../animation/Working";

const styles = {};
const Component = () => {
  return (
    <>
      <center>
        <div style={{ width: "100%", paddingTop: "16px", maxWidth: "400px" }}>
          <Working />
        </div>
      </center>
      <Typography
        color="primary"
        style={{
          fontWeight: 300,
          paddingTop: "0px",
          paddingBottom: "24px",
          textAlign: "center",
        }}
        variant="h3"
      >
        100%
        <br />
        women-of-color
        <br />
        ownership
      </Typography>
      <p />
      <Column>
        It feels odd to put that in a title. (Okay, sub-title.) And we know that
        some of you may roll your eyes.
        <p /> But after building and launching successful software projects for
        a long time, we think it's important. For too long, technology has been
        built by only a select few in our society. But its used by all of us.
        This has resulted in a mismatch in terms of builders and users.
        <p />
        Nowadays, diversity in who builds software is a key advantage. We feel
        that a more diverse team can better understand and orient software to
        meet real users' needs.
      </Column>
    </>
  );
};

export default withStyles(styles, { withTheme: true })(Component);
